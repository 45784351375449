import { IconProps } from './types';

const InfoIconCircle = ({ width = '22', height = '22' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="20" height="20" rx="10" stroke="#2B3F6C" strokeWidth="1.5" />
      <path
        d="M11.5 16L11.5 10"
        stroke="#2B3F6C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 10L11.5 10"
        stroke="#2B3F6C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 7L11.5 6"
        stroke="#2B3F6C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InfoIconCircle;
