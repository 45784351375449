import { reducers as commonReducers } from '@common/redux/root.reducer';
import application from '@common/redux/slices/application';
import { combineReducers } from '@reduxjs/toolkit';

export const reducers = {
  ...commonReducers,
  application,
};

export const rootReducer = combineReducers(reducers);

export type RootState = ReturnType<typeof rootReducer>;
