import React, { ReactNode, useCallback } from 'react';

export enum ButtonSpacing {
  NONE = 'none',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  TAB_SPACING = 'tab-spacing',
  BUTTON_GROUP = 'button-group',
  UNDEFINED = 'undefined',
}

const TEXT_SIZE_CLASSES = {
  xs: 'text-xs',
  sm: 'text-sm',
  base: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
};

const COLOR_CLASSES = {
  blue: 'bg-blue-brand text-black border-transparent',
  'blue-light': 'bg-blue-brand-light text-black border-transparent',
  black: 'bg-black text-white disabled:text-gray-500 border-transparent',
  transparent: 'bg-transparent text-black border-transparent',
  'underlined-black':
    'font-medium leading-tight tracking-tight underline disabled:text-gray-500 border-transparent',
  gray: 'bg-stone-100 text-black border-transparent',
  green: 'bg-green-brand  text-black border-transparent',
  'dark-gray': 'bg-[#EEE6DB] text-black border-transparent',
};

const PADDING_CLASSES = {
  [ButtonSpacing.UNDEFINED]: '',
  [ButtonSpacing.NONE]: 'px-0 py-0',
  [ButtonSpacing.SM]: 'px-6 py-3.5',
  [ButtonSpacing.MD]: 'px-7 py-4',
  [ButtonSpacing.LG]: 'px-9 py-4',
  [ButtonSpacing.XL]: 'px-8 py-7',
  [ButtonSpacing.TAB_SPACING]: 'px-3 py-2.5 md:px-6 md:py-3.5',
  [ButtonSpacing.BUTTON_GROUP]: 'px-[2px] py-0 sm:px-6 sm:py-3.5',
};

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  spacing?: ButtonSpacing;
  fullWidth?: boolean;
  color?:
    | 'black'
    | 'blue'
    | 'blue-light'
    | 'transparent'
    | 'underlined-black'
    | 'gray'
    | 'green'
    | 'dark-gray';

  roundedFull?: boolean;
  extraClassNames?: string;
  icon?: ReactNode;
  textSize?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
}

function Button({
  text,
  color = 'black',
  fullWidth = false,
  roundedFull = true,
  spacing = ButtonSpacing.MD,
  extraClassNames,
  icon,
  textSize = 'base',
  ...restProps
}: ButtonProps): JSX.Element {
  const baseClasses = ['border-2'];

  const colorClass = COLOR_CLASSES[color];
  const paddingClass = PADDING_CLASSES[spacing];
  const textClass = TEXT_SIZE_CLASSES[textSize];

  const getHoverClasses = useCallback(() => {
    switch (color) {
      case 'black':
        return 'hover:bg-white hover:border-2 hover:border-black hover:text-black';
      default:
        return 'transition-transform transform hover:scale-105 active:scale-100';
    }
  }, [color]);

  if (roundedFull) {
    baseClasses.push('rounded-full');
  } else {
    baseClasses.push('rounded-2xl');
  }

  if (fullWidth) {
    baseClasses.push('w-full');
  }

  if (extraClassNames) {
    baseClasses.push(extraClassNames);
  }

  if (!restProps.disabled) {
    baseClasses.push(getHoverClasses());
  }

  return (
    <button
      className={`${paddingClass} ${colorClass} ${textClass} ${baseClasses.join(' ')}`}
      {...{ ...restProps }}
    >
      <div className="flex size-full items-center justify-center gap-2">
        {icon && icon}
        <span>{text}</span>
      </div>
    </button>
  );
}

export default Button;
