import { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ImageCard from '@common/components/image-card/ImageCard';
import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import { useApplicationFlowInstance } from '@common/hooks';
import useTracker from '@common/hooks/useTracker';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';
import useLinks from '@monefit-ee/hooks/useLinks';
import endImg from '@src/assets/img/monefit-ee/end-page.webp';
import Button, { ButtonSpacing } from '@src/common/components/button/Button';
import { useAppDispatch, useAppSelector } from '@src/monefit-ee/hooks';

const EndPage = () => {
  const intl = useIntl();
  const { trackEvent } = useTracker({
    initEventName: TrackEvent.END_PAGE_INIT,
    errorTrackEventName: TrackEvent.END_PAGE_ERROR,
  });
  const { data } = useAppSelector((state) => state.application.flowInstance);
  const dispatch = useAppDispatch();
  const { contactUsLink } = useLinks();

  const handleContactClick = useCallback(() => {
    trackEvent(TrackEvent.END_PAGE_CONTACT);
    window.location.replace(contactUsLink);
  }, [trackEvent, contactUsLink]);

  const handleViewAccountClick = useCallback(() => {
    trackEvent(TrackEvent.END_PAGE_SUBMIT);
    window.location.replace((data?.responseData as any)?.accountUrl);
  }, [trackEvent, data?.responseData]);

  const [instanceId] = useApplicationFlowInstance();

  useEffect(() => {
    dispatch(performApplicationFlowAction({ action: FlowActions.FETCH, instanceId }));
  }, []);

  return (
    <SignupLayout>
      <ImageCard
        imageSrc={endImg}
        imageText={intl.formatMessage({
          defaultMessage: 'ALMOST SET!',
          description: 'End : "ALMOST SET!"',
        })}
      >
        <div className="flex h-full flex-col justify-between gap-12 sm:gap-0">
          <div className="text-center text-2xl font-normal leading-7 tracking-tight text-stone-950">
            <FormattedMessage
              defaultMessage="We’re just finishing up last few checks for your Creditline. Once everything’s all set, we’ll let you know by email."
              description="End: 'We’re just finishing up last few checks for your Creditline. Once everything’s all set, we’ll let you know by email.' "
            />{' '}
          </div>
          <div className="flex w-full flex-col gap-3">
            {!!data?.responseData?.accountUrl && (
              <Button
                onClick={handleViewAccountClick}
                text={intl.formatMessage({
                  defaultMessage: 'View my account',
                  description: 'End: "View my account"',
                })}
                fullWidth
              />
            )}
            <div className="flex items-center justify-center gap-2 text-center">
              <span className="text-base font-normal text-black">
                <FormattedMessage
                  defaultMessage="Have questions?"
                  description="End: 'Have questions?'"
                />{' '}
              </span>
              <Button
                onClick={handleContactClick}
                text={intl.formatMessage({
                  defaultMessage: 'Contact us',
                  description: 'End: "Contact us"',
                })}
                color="underlined-black"
                spacing={ButtonSpacing.NONE}
              />
            </div>
          </div>
        </div>
      </ImageCard>
    </SignupLayout>
  );
};

export default EndPage;
