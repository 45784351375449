import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import InfoIcon from '@common/components/icons/InfoIcon';
import Notification, { NotificationType } from '@common/components/notification/Notification';

const ApprovedLoanCard = ({
  approvedAmount,
  firstPayment,
  firstWithDraw,
  paymentDate,
  canProceedToNextStep,
  handleContinueClick,
}: {
  approvedAmount: number;
  firstWithDraw: number;
  firstPayment: number;
  paymentDate: Date;
  canProceedToNextStep: boolean;
  handleContinueClick: () => void | Promise<void>;
}) => {
  const intl = useIntl();
  const notificationMessage = canProceedToNextStep
    ? intl.formatMessage({
        defaultMessage: 'Agreements are signed off',
        description: 'Documents: "Agreements are signed off"',
      })
    : intl.formatMessage({
        defaultMessage: 'Please sign the documents below',
        description: 'Documents: "Please sign the documents below"',
      });

  return (
    <div className="inline-flex w-full flex-col items-start justify-start gap-7 rounded-3xl bg-slate-100 px-6 py-7 sm:p-12">
      <div className="inline-flex items-start justify-start gap-5 self-stretch">
        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-5">
          <div className="flex flex-col items-start justify-start gap-[5px] self-stretch">
            <div className="self-stretch text-lg font-semibold leading-tight  tracking-tight text-black">
              <FormattedMessage
                defaultMessage="APPROVED LOAN"
                description="Withdrawal: 'APPROVED LOAN'"
              />
            </div>
            <div className="text-[68px] font-black leading-[55px] text-black">
              {approvedAmount}€
            </div>
          </div>
        </div>
      </div>
      <div className="flex  flex-col items-start justify-start gap-5 self-stretch">
        <div className="flex h-10 flex-col items-start justify-start self-stretch">
          <div className="text-[15px] font-normal text-black">
            <FormattedMessage
              defaultMessage="First withdraw"
              description="Withdrawal: 'First withdraw'"
            />
          </div>
          <div className="text-[15px] font-semibold text-black">{firstWithDraw}€</div>
        </div>
        <div className="inline-flex items-start justify-between self-stretch rounded-[18px]">
          <div className="inline-flex flex-col items-start justify-start">
            <div className="text-[15px] font-normal text-black">
              <FormattedMessage
                defaultMessage="First payment"
                description="Withdrawal: 'First payment'"
              />
            </div>
            <div className="text-[15px] font-semibold text-black">{firstPayment} €</div>
          </div>
          <div className="inline-flex flex-col items-start justify-start">
            <div className="text-[15px] font-normal text-black">
              <FormattedMessage
                defaultMessage="Payment date"
                description="Withdrawal: 'Payment date'"
              />
            </div>
            <div className="text-[15px] font-semibold text-black">
              {paymentDate.toLocaleDateString()}
            </div>
          </div>
        </div>
      </div>
      <Notification
        type={
          canProceedToNextStep ? NotificationType.SuccessSecondary : NotificationType.InfoSecondary
        }
        textAlign="left"
        spacing="large"
        rounded="2xl"
        message={notificationMessage}
        icon={<InfoIcon />}
      />
      {canProceedToNextStep && (
        <Button
          type="button"
          onClick={handleContinueClick}
          fullWidth
          text={intl.formatMessage({
            defaultMessage: 'Continue',
            description: 'Decision: Continue button',
          })}
        />
      )}
    </div>
  );
};

export default ApprovedLoanCard;
