import React, { useEffect, useState } from 'react';

import dots from '@src/assets/amimations/Dots-animation.json';
import Lottie from 'lottie-react';

const LoadingIndicator: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 100);
  }, []);

  if (!isLoaded) {
    return <></>;
  }

  return (
    <div>
      <Lottie animationData={dots} loop={true} style={{ height: 40 }} />
    </div>
  );
};
export default LoadingIndicator;
