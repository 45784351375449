import { IconProps } from './types';

const EyeIcon = ({ width = '44', height = '31' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.47626 17.2404C1.8829 16.1554 1.88291 14.8443 2.47629 13.7593C6.24688 6.86468 13.5762 2.1875 21.9999 2.1875C30.4238 2.1875 37.7532 6.86483 41.5237 13.7596C42.1171 14.8446 42.1171 16.1558 41.5237 17.2407C37.7531 24.1353 30.4238 28.8125 22.0001 28.8125C13.5762 28.8125 6.2468 24.1352 2.47626 17.2404Z"
        stroke="black"
        strokeWidth="3.25"
      />
      <circle cx="22" cy="15.5" r="6.65625" stroke="black" strokeWidth="3.25" />
    </svg>
  );
};

export default EyeIcon;
