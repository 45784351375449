import { FC, JSX, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import LoadingCard from '@common/components/loader/LoadingCard';
import { BaseError } from '@common/utils';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import errorImg from '@src/assets/img/monefit-ee/error-img.webp';

export interface ErrorBoundaryPageProps {
  error?: BaseError;
  hasSignupLayout?: boolean;
}

interface ErrorCardError {
  name: string;
  message: string;
  stack?: string;
}

export const ErrorCard = ({ error }: { error: ErrorCardError }) => {
  const intl = useIntl();
  const { REACT_APP_ENVIRONMENT } = process.env;

  return (
    <div className="flex flex-col items-center gap-3">
      <LoadingCard imgSrcProp={errorImg} text={error.message} showIndicator={false} />
      <div className="w-1/2">
        <Button
          fullWidth
          text={intl.formatMessage({
            defaultMessage: 'Try again',
            description: 'ErrorBoundaryPage: Try again button text',
          })}
          onClick={() => window.location.reload()}
        />
      </div>
      {!!error?.stack && ['development', 'staging'].includes(REACT_APP_ENVIRONMENT) && (
        <div className="flex w-full items-center justify-center sm:w-[610px]">
          <div className="inline-flex items-start justify-start rounded-3xl bg-white p-6">
            <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{error.stack}</pre>
          </div>
        </div>
      )}
    </div>
  );
};

const ErrorBoundaryPage: FC<ErrorBoundaryPageProps> = ({
  error: propsError,
  hasSignupLayout = true,
}): JSX.Element => {
  const intl = useIntl();
  const [error, setError] = useState<ErrorCardError>();

  useEffect(() => {
    const fallbackName = intl.formatMessage({
      defaultMessage: 'Oops!',
      description: 'ErrorBoundaryPage: Error fallbackName (oops)',
    });
    const fallbackMessage = intl.formatMessage({
      defaultMessage:
        'An error occurred while processing the request. Please contact us if you believe this is a server error. Thank you.',
      description: 'ErrorBoundaryPage: Error fallbackMessage',
    });

    setError({
      name: fallbackName,
      message: fallbackMessage,
      stack: propsError?.stack,
    });
  }, [intl, propsError, setError]);

  if (!error) {
    return (
      <div>
        <FormattedMessage defaultMessage="Loading..." description="ErrorBoundaryPage: Loading" />
      </div>
    );
  }

  return hasSignupLayout ? (
    <MainLayout>
      <ErrorCard error={error} />{' '}
    </MainLayout>
  ) : (
    <ErrorCard error={error} />
  );
};

export default ErrorBoundaryPage;
