import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useLinks from '@monefit-ee/hooks/useLinks';
import pending from '@src/assets/img/monefit-ee/pending.webp';
import rejected from '@src/assets/img/monefit-ee/rejected.webp';

import Button, { ButtonSpacing } from '../button/Button';
import ImageCard from '../image-card/ImageCard';

export enum LoanStatus {
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

interface LoanStatusCardProps {
  status: LoanStatus;
}

const IMAGES = {
  [LoanStatus.REJECTED]: rejected,
  [LoanStatus.PENDING]: pending,
};

const LoanStatusCard = ({ status }: LoanStatusCardProps) => {
  const { formatMessage } = useIntl();

  const DECRIPTION_TEXTS = useMemo(
    () => ({
      [LoanStatus.REJECTED]: (
        <FormattedMessage
          defaultMessage={
            '<p>Thank you for choosing Monefit for your financial needs.{br}{br} Unfortunately, we are unable to approve your loan at this time.{br}{br} Please feel free to get in touch, if you wish to get more information and discuss potential options for the future.</p>'
          }
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      ),
      [LoanStatus.PENDING]: (
        <FormattedMessage
          defaultMessage={
            'The loan application has undergone an initial review and, due to specific criteria or complexities, requires manual oversight for final determination.'
          }
          description="Pending loan text"
        />
      ),
    }),
    []
  );

  const CARD_TEXTS = useMemo(
    () => ({
      [LoanStatus.REJECTED]: formatMessage({
        defaultMessage: 'SORRY',
        description: 'Rejected loan image text (SORRY)',
      }),
      [LoanStatus.PENDING]: formatMessage({
        defaultMessage: 'PENDING',
        description: 'PENDING loan image text (PENDING)',
      }),
    }),
    [formatMessage]
  );

  const description = DECRIPTION_TEXTS[status];
  const imageText = CARD_TEXTS[status];
  const imageSrc = IMAGES[status];
  const { contactUsLink } = useLinks();

  return (
    <ImageCard hasSmallerImageText {...{ imageSrc, imageText }}>
      <>
        <div className="flex h-full flex-col items-start justify-between gap-8 sm:gap-2.5">
          <div className="text-lg font-medium leading-normal text-black">{description}</div>
          <Button
            onClick={() => window.location.replace(contactUsLink)}
            text={formatMessage({
              defaultMessage: 'Contact us',
              description: 'Contact us button text',
            })}
            fullWidth
            spacing={ButtonSpacing.LG}
          />
        </div>
      </>
    </ImageCard>
  );
};

export default LoanStatusCard;
