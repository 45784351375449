import LanguageSelect from '@common/components/LanguageSelect';
import { useAppSelector } from '@monefit-ee/hooks';

import LogoIcon from '../icons/LogoIcon';
import FlowProgressBar from '../progress-bar/FlowProgressBar';

const Header = ({ hideProgressBar = false }: { hideProgressBar?: boolean }) => {
  const { loading, meta } = useAppSelector((st) => st.application.flowInstance);
  return (
    <div className="flex w-full flex-col items-center justify-center gap-3 p-2 lg:flex-row lg:justify-between lg:gap-0">
      <div className="flex flex-[0.5] items-center justify-start gap-5">
        <LogoIcon />
        <LanguageSelect loading={loading} showLoader={meta?.showLoader} />
      </div>
      {!hideProgressBar && <FlowProgressBar />}
      <div className="w-full flex-[0.5]"></div>
    </div>
  );
};

export default Header;
