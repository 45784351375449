import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useLocale } from '@common/hooks';
import { getIsLoggedIn } from '@common/utils';

const PublicRoute = ({ children }: { children: ReactNode }) => {
  const isLoggedIn = getIsLoggedIn();
  const { localeWithCountry } = useLocale();

  return <>{isLoggedIn ? <Navigate to={`/${localeWithCountry}/user`} /> : children}</>;
};

export default PublicRoute;
