import { useMemo } from 'react';

import { Locales, useLocale } from '@common/hooks';

const useLinks = () => {
  const { locale } = useLocale();
  const { REACT_APP_ENVIRONMENT } = process.env;

  const baseUrl = useMemo(
    () =>
      REACT_APP_ENVIRONMENT === 'production' ? 'https://monefit.com' : 'https://test.monefit.dev',
    [REACT_APP_ENVIRONMENT]
  );

  const urlLocale = useMemo(() => {
    switch (locale) {
      case Locales.ET:
        return 'ee-ee';

      case Locales.RU:
        return 'ru-ee';

      default:
        return 'ee-ee';
    }
  }, [locale]);

  const contactUsLink = useMemo(() => `${baseUrl}/${urlLocale}/kontakt`, [baseUrl, urlLocale]);
  const creditLineLink = useMemo(() => `${baseUrl}/${urlLocale}/credit-line`, [baseUrl, urlLocale]);
  const faqLink = useMemo(() => `${creditLineLink}/#accordionFaq`, [creditLineLink]);

  return {
    contactUsLink,
    creditLineLink,
    faqLink,
  };
};
export default useLinks;
