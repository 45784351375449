import { InputHTMLAttributes, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import InfoIconCircle from '@common/components/icons/InfoIconCircle';

import InputLabel from '../InputLabel';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  /** Field name. */
  name: string;
  type?: 'text' | 'password' | 'email' | 'number' | 'tel';
  label?: string;
  desciption?: string;
  step?: string;
}

const TextInput = ({
  defaultValue,
  disabled,
  name,
  label,
  type,
  step = '0.01',
  desciption,
  ...props
}: TextInputProps) => {
  const {
    fieldState: { error },
  } = useController({
    defaultValue,
    name,
    rules: {},
  });

  const {
    register,
    formState: { isSubmitting },
    getValues,
    trigger,
  } = useFormContext();

  const { onChange, ...registration } = register(name);

  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative">
      <input
        className={`h-16 w-full rounded-xl border border-gray-300 px-7 font-semibold 
          placeholder:font-normal placeholder:text-gray-400 focus:outline-none ${
            label ? 'pt-3' : ''
          } `}
        disabled={isSubmitting || disabled}
        {...registration}
        {...props}
        {...{ type, step }}
        onChange={(e) => onChange(e)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          trigger(name);
        }}
        placeholder={label ? '' : props.placeholder ?? ''}
      />
      {!!label && (
        <InputLabel
          name={name}
          text={label}
          shouldMinimize={isFocused || props.value || defaultValue || getValues(name)}
        />
      )}
      {!!desciption && (
        <div className="mt-2 flex gap-2 text-[14px] font-normal text-black">
          <div>
            <InfoIconCircle />
          </div>
          {desciption}
        </div>
      )}
      {!!error?.message && (
        <div className="mt-2 text-xs font-semibold text-rose-400">{error?.message?.toString()}</div>
      )}
    </div>
  );
};

export default TextInput;
