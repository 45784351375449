import LoadingCard from '@common/components/loader/LoadingCard';
import main_loader from '@src/assets/img/monefit-es/main_loader.webp';

const MainLoader = ({
  showIndicator = true,
  extraClassNames,
}: {
  showIndicator?: boolean;
  extraClassNames?: string;
}) => {
  return (
    <div className="flex size-full items-center justify-center">
      <div className="flex w-full items-center justify-center text-black sm:w-[530px]">
        <LoadingCard
          imgSrcProp={main_loader}
          showIndicator={showIndicator}
          extraClassNames={extraClassNames}
        />
      </div>
    </div>
  );
};

export default MainLoader;
