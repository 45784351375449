import { FC, JSX } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';

import { useLocale } from '@common/hooks';
import useMessages from '@common/hooks/useMessages';
import { store } from '@monefit-ee/redux';
import Router from '@src/monefit-ee/router/Router';

const App: FC = (): JSX.Element | null => {
  const { locale } = useLocale();
  const { messages, messagesLoading } = useMessages();
  if (!messages || messagesLoading) {
    return null;
  }

  return (
    <IntlProvider locale={locale} messages={messages}>
      <Provider store={store}>
        <div data-testid="monefit-ee-app">
          <Router />
        </div>
      </Provider>
    </IntlProvider>
  );
};

export default App;
