import React from 'react';
import { Helmet } from 'react-helmet-async';

interface MetaTagsProps {
  title: string;
  description: string;
  url?: string;
  image?: string;
}

const MetaTags: React.FC<MetaTagsProps> = ({ title, description, url, image }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content="https://app.monefit.com/en-es/sign-up" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content="https://monefit.com/wp-content/uploads/2023/04/image-78.png"
      />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="app.monefit.com" />
      <meta property="twitter:url" content="https://app.monefit.com/en-es/sign-up" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content="https://monefit.com/wp-content/uploads/2023/04/image-78.png"
      />
    </Helmet>
  );
};

export default MetaTags;
