import { FormattedMessage } from 'react-intl';

import './coming-soon.css';

const ComingSoon = () => {
  return (
    <div className="relative flex h-[2.275rem] w-[9.47rem] items-center justify-center">
      <div className="animate-color-transition absolute left-0 top-0 size-full rounded-full bg-white"></div>
      <span
        className="font-secondary relative text-[15px] font-semibold text-black"
        style={{ letterSpacing: '3px' }}
      >
        <FormattedMessage defaultMessage="Coming soon" description="Monefit-ES: Coming soon" />
      </span>
    </div>
  );
};

export default ComingSoon;
