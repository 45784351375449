import { IconProps } from './types';

const InfoIcon = ({ width = '10', height = '16' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.20833 6.79167C1.20833 6.12432 1.74932 5.58333 2.41667 5.58333H4.83333C5.50068 5.58333 6.04167 6.12432 6.04167 6.79167V12.8333H8.45833C9.12568 12.8333 9.66667 13.3743 9.66667 14.0417C9.66667 14.709 9.12568 15.25 8.45833 15.25H1.20833C0.540989 15.25 0 14.709 0 14.0417C0 13.3743 0.540989 12.8333 1.20833 12.8333H3.625V8H2.41667C1.74932 8 1.20833 7.45901 1.20833 6.79167Z"
        fill="#15171B"
      />
      <path
        d="M4.22917 4.375C5.23018 4.375 6.04167 3.56352 6.04167 2.5625C6.04167 1.56148 5.23018 0.75 4.22917 0.75C3.22815 0.75 2.41667 1.56148 2.41667 2.5625C2.41667 3.56352 3.22815 4.375 4.22917 4.375Z"
        fill="#15171B"
      />
    </svg>
  );
};

export default InfoIcon;
