import { FC, useCallback, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

import { FlowSteps } from '@common/services/application';
import { MissingImplementationError } from '@common/utils';
import { kebabCase, startCase } from 'lodash';

import useCountry, { Country } from './useCountry';

export interface ApplicationRoutingPageMap {
  flowStepId: FlowSteps;
  path: string;
  element: FC;
}

const COUNTRIES_PAGE_MAP = {
  [Country.EE]: 'monefit-ee',
  [Country.ES]: 'monefit-es',
};

/**
 * Dynamically load page map for application flow routing based on country (from URL)
 * Page map should be defined under src/project-id/pages/index.tsx
 */
export const useApplicationRoutingPageMap = (
  flowSteps: FlowSteps[]
): [ApplicationRoutingPageMap[], boolean] => {
  const { showBoundary } = useErrorBoundary();
  const [loading, setLoading] = useState<boolean>(false);
  const [applicationRoutingPageMap, setApplicationRoutingPageMap] = useState<
    ApplicationRoutingPageMap[]
  >([]);

  const { country } = useCountry();
  const initPageMap = useCallback(async () => {
    if (!country) {
      return {};
    }
    setLoading(true);
    const { REACT_APP_ENVIRONMENT } = process.env;
    const Pages: { [key: string]: FC } = await import(`@src/${COUNTRIES_PAGE_MAP[country]}/pages`);

    const map: ApplicationRoutingPageMap[] = Object.entries(flowSteps).map((fs, i) => {
      const flowStepId = fs[1] as FlowSteps;
      const path = `${kebabCase(fs[1])}`.replace(' ', '');
      const componentName: string = `${startCase(fs[1])}Page`.replace(' ', '');
      const element = Pages[componentName];

      // For debugging purposes
      // if (REACT_APP_ENVIRONMENT === 'development' && !element) {
      //   showBoundary(
      //     new MissingImplementationError(
      //       `Page is defined in application flow but has no implementation: ${componentName}`
      //     )
      //   );
      // }

      return {
        flowStepId,
        path,
        element,
      };
    });

    setApplicationRoutingPageMap(map);
    setLoading(false);
  }, [flowSteps, showBoundary, country]);

  useEffect(() => {
    initPageMap();
  }, [flowSteps]);

  return [applicationRoutingPageMap, loading];
};
