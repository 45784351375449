import { useEffect, useRef, useState } from 'react';

const events = ['mousemove', 'keydown', 'scroll', 'click', 'touchstart', 'wheel', 'mousedown'];

const useInactivityDetection = () => {
  const [inactiveSeconds, setInactiveSeconds] = useState(0);
  const timerRef = useRef<number | null>(null);
  const intervalRef = useRef<number | null>(null);

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setInactiveSeconds(0);
  };

  useEffect(() => {
    const handleActivity = () => {
      resetTimer();
    };

    const updateInactiveSeconds = () => {
      setInactiveSeconds((prev) => prev + 1);
    };

    events.forEach((event) => window.addEventListener(event, handleActivity));
    resetTimer();
    intervalRef.current = window.setInterval(updateInactiveSeconds, 1000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      events.forEach((event) => window.removeEventListener(event, handleActivity));
    };
  }, []);

  return inactiveSeconds;
};

export default useInactivityDetection;
