import { IconProps } from './types';

const GoogleIcon = ({ width = '19', height = '19' }: IconProps) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.89 9.70419C17.89 9.06601 17.8327 8.45237 17.7264 7.86328H9.25V11.3446H14.0936C13.885 12.4696 13.2509 13.4228 12.2977 14.061V16.3192H15.2064C16.9082 14.7524 17.89 12.4451 17.89 9.70419Z"
          fill="#4285F4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.25 18.5009C11.68 18.5009 13.7173 17.695 15.2064 16.3205L12.2977 14.0623C11.4918 14.6023 10.4609 14.9214 9.25 14.9214C6.90591 14.9214 4.92182 13.3382 4.21409 11.2109H1.20728V13.5428C2.68818 16.4841 5.73182 18.5009 9.25 18.5009Z"
          fill="#34A853"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.21409 11.2108C4.03409 10.6708 3.93182 10.094 3.93182 9.5008C3.93182 8.90762 4.03409 8.3308 4.21409 7.7908V5.45898H1.20727C0.597727 6.67398 0.25 8.04853 0.25 9.5008C0.25 10.9531 0.597727 12.3276 1.20727 13.5426L4.21409 11.2108Z"
          fill="#FBBC05"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.25 4.07955C10.5714 4.07955 11.7577 4.53364 12.6905 5.42545L15.2718 2.84409C13.7132 1.39182 11.6759 0.5 9.25 0.5C5.73182 0.5 2.68818 2.51682 1.20728 5.45818L4.21409 7.79C4.92182 5.66273 6.90591 4.07955 9.25 4.07955Z"
          fill="#EA4335"
        />
      </svg>
    </>
  );
};

export default GoogleIcon;
