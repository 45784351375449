/**
 * Events from https://docs.google.com/spreadsheets/d/16L_gyDu1y4zbCz9AZAYw7tp_CP7U3X1raPKQ5MF29L8/edit#gid=850625980
 */

export enum TrackEvent {
  // START PAGE
  START_PAGE_INIT = 'mf_com_cl_ee_index',
  START_PAGE_SUBMIT = 'mf_com_cl_ee_index_cta',
  START_PAGE_SIGNUP = 'mf_com_cl_ee_index_signup',
  START_PAGE_SIGNIN = 'mf_com_cl_ee_index_signin',
  START_PAGE_SINGIN_CALC = 'mf_com_cl_ee_index_signin_calc',
  START_PAGE_SECCI = 'mf_com_cl_ee_index_secci',
  START_PAGE_LANGUAGE = 'mf_com_cl_ee_index_language',

  // SIGNUP PAGE
  SIGNUP_PAGE_INIT = 'flow_cl_ee_signup_page',
  SIGNUP_PAGE_SMART_ID_SELECT = 'flow_cl_ee_signup_smartid',
  SIGNUP_PAGE_MOBILE_ID_SELECT = 'flow_cl_ee_signup_mobileid',
  SIGNUP_PAGE_ID_CARD_SELECT = 'flow_cl_ee_signup_cardid',
  SIGNUP_PAGE_DIGIT_ENTETERED = 'flow_cl_ee_signup_text',
  SIGNUP_PAGE_SUBMIT = 'flow_cl_ee_signup_cta',
  SINGUP_PAGE_ERROR = 'flow_cl_ee_signup_error',

  // SIGNUP PROCESSING PAGE
  SIGNUP_PROCESSING_INIT = 'flow_cl_ee_signup_processing_page',
  SIGNUP_PROCESSING_ERROR = 'flow_cl_ee_signup_error2',

  // EMAIL PAGE
  EMAIL_PAGE_INIT = 'flow_cl_ee_email_page',
  EMAIL_PAGE_EMAIL_ENTERED = 'flow_cl_ee_email_field',
  EMAIL_PAGE_CHECKBOX_CHECKED = 'flow_cl_ee_email_check',
  EMAIL_PAGE_SUBMIT = 'flow_cl_ee_email_cta',
  EMAIL_PAGE_ERROR = 'flow_cl_ee_email_error',

  // EMAIL OTP PAGE
  EMAIL_OTP_PAGE_INIT = 'flow_cl_ee_email_verify_page',
  EMAIL_OTP_PAGE_CODE_ENTERED = 'flow_cl_ee_email_verify_code',
  EMAIL_OTP_PAGE_CODE_RESEND = 'flow_cl_ee_email_verify_otpresend',
  EMAIL_OTP_PAGE_SUBMIT = 'flow_cl_ee_email_verify_cta',
  EMAIL_OTP_PAGE_ERROR = 'flow_cl_ee_email_otp_error',

  // ADDRESS PAGE
  ADDRESS_PAGE_INIT = 'flow_cl_ee_address_page',
  ADDRESS_PAGE_ADDRESS_ENTERED = 'flow_cl_ee_address_text',
  ADDRESS_PAGE_YEARS_OF_RES_SELECT = 'flow_cl_ee_address_yearsofres',
  ADDRESS_PAGE_SUBMIT = 'flow_cl_ee_address_cta',
  ADDRESS_PAGE_ERROR = 'flow_cl_ee_address_error',

  // FINANCES PAGE
  FINANCES_PAGE_INIT = 'flow_cl_ee_finance_page',
  FINANCES_PAGE_LOAN_PURPOSE_SELECT = 'flow_cl_ee_finance_clpurpose',
  FINANCES_PAGE_EMPLOYMENT_SELECT = 'flow_cl_ee_finance_employment',

  FINANCES_PAGE_INCOME_SELECT = 'flow_cl_ee_finance_income',
  FINANCES_PAGE_EXPENSES_SELECT = 'flow_cl_ee_finance_expenses',
  FINANCES_PAGE_MARITAL_SELECT = 'flow_cl_ee_finance_marital',
  FINANCES_PAGE_DEPENDENTS_SELECT = 'flow_cl_ee_finance_dependents',
  FINANCES_PAGE_USED_CL_SELECT = 'flow_cl_ee_finance_used_cl_before',
  FINANCES_PAGE_ACTIVE_LOANS_SELECT = 'flow_cl_ee_finance_active_cl',
  FINANCES_PAGE_ACTIVE_LOANS_DETAILS = 'flow_cl_ee_finance_active_cl1',
  FINANCES_PAGE_ADD_LOANS_CLICKED = 'flow_cl_ee_finance_active_add_more',
  FINANCES_PAGE_IS_PEP_SELECTED = 'flow_cl_ee_finance_pep',
  FINANCES_PAGE_SUBMIT = 'flow_cl_ee_finance_cta',
  FINANCES_PAGE_ERROR = 'flow_cl_ee_finance_error',

  // CONSENT PAGE
  CONSENT_PAGE_INIT = 'flow_cl_ee_creditchecks_page',
  CONSENT_PAGE_CHECKBOX_SELECT = 'flow_cl_ee_creditchecks_consent',
  CONSENT_PAGE_SUBMIT = 'flow_cl_ee_creditchecks_cta',
  CONSENT_PAGE_ERROR = 'flow_cl_ee_creditchecks_error',

  // PROCESSING PAGE 2
  DECISION_PAGE_INIT = 'flow_cl_ee_app_processing_page',
  DECISION_PAGE_ERROR = 'flow_cl_ee_app_processing_error',

  // DECLINED PAGE
  DECLINED_PAGE_INIT = 'flow_cl_ee_app_declined_page',
  DECLINED_PAGE_ERROR = 'flow_cl_ee_app_declined_error',

  // APPROVED PAGE
  APPROVED_PAGE_INIT = 'flow_cl_ee_app_approved_page',
  APPROVED_PAGE_AMOUNT_SELECTED = 'flow_cl_ee_app_approved_ddamount',
  APPROVED_PAGE_SUBMIT = 'flow_cl_ee_app_approved_cta',
  APPROVED_PAGE_ERROR = 'flow_cl_ee_app_approved_error',

  // DOCUMENTS PAGE
  DOCUMENTS_PAGE_INIT = 'flow_cl_ee_contract_page',
  DOCUMENTS_PAGE_SECCI_READ = 'flow_cl_ee_contract_secci_read',
  DOCUMENTS_PAGE_SECCI_ACCEPT = 'flow_cl_ee_contract_secci_accept',
  DOCUMENTS_PAGE_CONTRACT_READ = 'flow_cl_ee_contract_contract_read',
  DOCUMENTS_PAGE_CONTRACT_ACCEPT = 'flow_cl_ee_contract_contract_accept',
  DOCUMENTS_PAGE_SUBMIT = 'flow_cl_ee_contract_cta',
  DOCUMENTS_PAGE_ERROR = 'flow_cl_ee_contract_error',

  // SIGNED PAGE
  SIGNED_PAGE_INIT = 'flow_cl_ee_signed_page',
  SIGNED_PAGE_SUBMIT = 'flow_cl_ee_signed_cta',
  SIGNED_PAGE_ERROR = 'flow_cl_ee_contract2_error',

  // PHONE PAGE
  PHONE_PAGE_INIT = 'flow_cl_ee_mobile_page',
  PHONE_PAGE_NUMBER_ENTERED = 'flow_cl_ee_mobile_field',
  PHONE_PAGE_SUBMIT = 'flow_cl_ee_mobile_cta',
  PHONE_PAGE_ERROR = 'flow_cl_ee_mobile_error',

  // PHONE OTP
  PHONE_OTP_PAGE_INIT = 'flow_cl_ee_mobile_verify_page',
  PHONE_OTP_PAGE_CODE_ENTERED = 'flow_cl_ee_mobile_verify_code',
  PHONE_OTP_PAGE_CODE_RESEND = 'flow_cl_ee_mobile_verify_otpresend',
  PHONE_OTP_PAGE_SUBMIT = 'flow_cl_ee_mobile_verify_cta',
  PHONE_OTP_PAGE_ERROR = 'flow_cl_ee_mobile_otp_error',

  // END PAGE
  END_PAGE_INIT = 'flow_cl_ee_final_page',
  END_PAGE_SUBMIT = 'flow_cl_ee_final_cta',
  END_PAGE_CONTACT = 'flow_cl_ee_final_contact',
  END_PAGE_ERROR = 'flow_cl_ee_final_error',

  // REDIRECT PAGE
  REDIRECT_PAGE_INIT = 'flow_cl_ee_redirect_page',
  REDIRECT_PAGE_ERROR = 'flow_cl_ee_redirect_error',
}
