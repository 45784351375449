import { useEffect, useState } from 'react';

export enum CookieConsentType {
  MARKETING = 'marketing',
  NECESSARY = 'necessary',
  PREFERENCES = 'preferences',
  STATISTICS = 'statistics',
}

export const DEFAULT_CONSENT_VALUES = {
  [CookieConsentType.MARKETING]: false,
  [CookieConsentType.NECESSARY]: false,
  [CookieConsentType.PREFERENCES]: false,
  [CookieConsentType.STATISTICS]: false,
};

/**
 * Hook to get the current unified cookie consents from Cookiebot.
 */
export const useCookieConsents = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [consents, setConsents] =
    useState<Record<CookieConsentType, boolean>>(DEFAULT_CONSENT_VALUES);

  useEffect(() => {
    const handleConsentHandlerStateChange = () => {
      if (!window.Cookiebot) {
        setLoaded(false);
        return;
      }

      setConsents({
        [CookieConsentType.MARKETING]:
          window.Cookiebot?.consents?.marketing ??
          DEFAULT_CONSENT_VALUES[CookieConsentType.MARKETING],
        [CookieConsentType.NECESSARY]:
          window.Cookiebot?.consents?.necessary ??
          DEFAULT_CONSENT_VALUES[CookieConsentType.NECESSARY],
        [CookieConsentType.PREFERENCES]:
          window.Cookiebot?.consents?.preferences ??
          DEFAULT_CONSENT_VALUES[CookieConsentType.PREFERENCES],
        [CookieConsentType.STATISTICS]:
          window.Cookiebot?.consents?.statistics ??
          DEFAULT_CONSENT_VALUES[CookieConsentType.STATISTICS],
      });
      setLoaded(true);
    };

    handleConsentHandlerStateChange();

    window.addEventListener('CookiebotOnLoad', handleConsentHandlerStateChange);
    window.addEventListener('CookiebotOnConsentReady', handleConsentHandlerStateChange);
    window.addEventListener('CookiebotOnAccept', handleConsentHandlerStateChange);
    window.addEventListener('CookiebotOnDecline', handleConsentHandlerStateChange);

    return () => {
      window.removeEventListener('CookiebotOnLoad', handleConsentHandlerStateChange);
      window.removeEventListener('CookiebotOnConsentReady', handleConsentHandlerStateChange);
      window.removeEventListener('CookiebotOnAccept', handleConsentHandlerStateChange);
      window.removeEventListener('CookiebotOnDecline', handleConsentHandlerStateChange);
    };
  }, [loaded, setLoaded, setConsents]);

  return {
    loaded,
    consents,
  };
};
