import { useCallback, useMemo, useState } from 'react';

import Button, { ButtonSpacing } from '../button/Button';

export interface ITab {
  title: string;
  content?: JSX.Element;
  onClick?: () => void;
}

interface TabsProps {
  defaultOpenIndex?: number;
  tabs: ITab[];
}

const Tabs = ({ tabs, defaultOpenIndex = 0 }: TabsProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(
    tabs[defaultOpenIndex] ? defaultOpenIndex : 0
  );

  const handleTabSelect = useCallback((tab: ITab, index: number) => {
    setActiveTabIndex(index);
    tab.onClick && tab.onClick();
  }, []);

  const justifyClass = useMemo(
    () => (tabs.length < 3 ? 'justify-start' : 'justify-between'),
    [tabs]
  );

  return (
    <>
      <div className={`flex ${justifyClass} gap-1 rounded-full bg-white p-2 sm:gap-3`}>
        {tabs.map((t, i) => (
          <Button
            spacing={ButtonSpacing.TAB_SPACING}
            color={i === activeTabIndex ? 'blue' : 'transparent'}
            key={t.title}
            onClick={() => handleTabSelect(t, i)}
            text={t.title}
            extraClassNames={'font-medium'}
          />
        ))}
      </div>
      {!!tabs[activeTabIndex]?.content && (
        <div className="mt-5 flex size-full flex-col items-stretch">
          {tabs[activeTabIndex]?.content}
        </div>
      )}
    </>
  );
};

export default Tabs;
