import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import useTracker from '@common/hooks/useTracker';
import { TrackEvent } from '@common/utils/amplitude/events';

import AddressForm from './AddressForm';

const AddressPage = () => {
  useTracker({
    initEventName: TrackEvent.ADDRESS_PAGE_INIT,
    errorTrackEventName: TrackEvent.ADDRESS_PAGE_ERROR,
  });

  return (
    <SignupLayout>
      <AddressForm />
    </SignupLayout>
  );
};

export default AddressPage;
