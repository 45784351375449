import { useIntl } from 'react-intl';

import LoadingCard from '@common/components/loader/LoadingCard';
import SignupLayout from '@common/components/sign-up-layout/SignupLayout';

const SignUpLoaderPage = ({
  text,
  shouldSimulateRedirect = false,
}: {
  text?: string;
  shouldSimulateRedirect?: boolean;
}) => {
  const intl = useIntl();

  return (
    <SignupLayout>
      <LoadingCard
        text={
          text ??
          intl.formatMessage({
            defaultMessage: "You're all set to register with your Smart ID",
            description: 'Identification: "You re all set to register with your Smart ID"',
          })
        }
      />
    </SignupLayout>
  );
};

export default SignUpLoaderPage;
