import { getCoreServiceBaseUrls } from '@common/utils';
import axios from 'axios';

const { applicationBaseUrl } = getCoreServiceBaseUrls();
export const applicationService = axios.create({
  baseURL: applicationBaseUrl,
  headers: {
    'Content-type': 'application/json',
  },
});

export enum FlowType {
  APPLICATION = 'application',
  DRAWDOWN = 'drawdown',
}

export enum SubStep {
  SMART_ID_PENDING = 'smart-id-pending',
  MOBILE_ID_PENDING = 'mobile-id-pending',
  ID_CARD_PENDING = 'id-card-pending',
  BANK_START = 'bank-start',
  BANK_IN_PROGRESS = 'bank-in-progress',
  BANK_PENDING = 'bank-pending',
}

export enum FlowActions {
  SUBMIT = 'submit',
  FETCH = 'fetch',
  RESEND = 'resend',
  CHECK = 'check',
  PROVIDERS = 'providers',
  BACK = 'back',
  SWITCH_LOCALE = 'switchLocale',
}

export enum FlowSteps {
  START = 'Start',
  IDENTIFICATION = 'Identification',
  EMAIL = 'Email',
  EMAIL_OTP = 'EmailOtp',
  ADDRESS = 'Address',
  FINANCES = 'Finances',
  BANK = 'Bank',
  DECISION = 'Decision',
  DECLINED = 'Declined',
  WITHDRAWAL = 'Withdrawal',
  DOCUMENTS = 'Documents',
  PHONE = 'Phone',
  PHONE_OTP = 'PhoneOtp',
  END = 'End',
  REDIRECT = 'Redirect',
  VALIDATION = 'Validation',
}

export enum IdentificationProvider {
  SMART_ID = 'smart-id',
  MOBILE_ID = 'mobile-id',
  ID_CARD = 'id-card',
}

export enum IdentificationReason {
  REGISTER = 'register',
}

export interface GetFlowInstanceRequest {
  instanceId?: string;
}

export interface GetPostFlowInstanceResponse {
  id: string;
  currentStepId: FlowSteps;
  allowedBackStepId: string | null;
  responseData: ServerResponse;
  prefilledData: unknown;
  notifications: unknown;
  errors: unknown;
  createdAt: string;
  updatedAt: string;
  isResumed: boolean;
  sessionId: string;
  progressPercentage: number;
}

export interface PostFlowInstanceRequest {
  action: FlowActions;
  data?: unknown;
  instanceId?: string;
}

export interface FlowDefinition {
  id: string;
  identifier: string; // TODO: enum?
  entryStep: string; // TODO: enum?
  instanceKey: string;
  expiryInterval: string;
  createdAt: string;
  updatedAt: string;
  originatorIdentifier: string; // TODO enum?
  clientUrl: string;
  resumeType: string; // TODO enum?
  locales: string[]; // e.g. [et-ee, et-en, ...etc]
}

export type IdentificationProvidersResponse = ['smart-id', 'mobile-id', 'id-card'];

export interface IdentificationSubmitResponse {
  code: string;
  hash: string;
  provider: IdentificationProvider;
  identificator: string;
  personalCode: string;
  phone: string;
}

export interface IdentificationCheckResponse {
  lastName: string;
  firstName: string;
  personalCode: string;
}

export interface WithdrawalFetch {
  limit: number;
  bankAccounts: string[];
  amount: number;
  firstRepaymentAmount: number;
  firstRepaymentDate: string;
}

export interface DocumentsFetch {
  secci: string;
  loan_agreement: string;
  amount: number;
  limit: number;
  firstRepaymentAmount: number;
  firstRepaymentDate: string;
}

export interface OtpCodeResponse {
  otpCode: string;
}

export interface BankSubmitResponse {
  clientId: string;
  invitationId: string;
  redirectUrl: string;
}

export interface DocumentsResponse {
  documents: {
    privacy_policy?: string;
    terms_and_conditions?: string;
    third_party_partner_terms?: {
      [key: string]: string;
    };
  };
}
export interface RedirectResponse {
  redirectUrl: string;
}

export type ServerResponse =
  | IdentificationProvidersResponse
  | IdentificationSubmitResponse
  | IdentificationCheckResponse
  | WithdrawalFetch
  | DocumentsFetch
  | OtpCodeResponse
  | BankSubmitResponse
  | DocumentsResponse
  | RedirectResponse
  | any;
