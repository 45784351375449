import { useEffect } from 'react';

import MainLoader from '@common/components/loader/MainLoader';
import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import { useApplicationFlowInstance } from '@common/hooks';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { useAppDispatch } from '@monefit-ee/hooks';

const ValidationPage = () => {
  const dispatch = useAppDispatch();
  const [instanceId] = useApplicationFlowInstance();

  useEffect(() => {
    dispatch(performApplicationFlowAction({ action: FlowActions.SUBMIT, instanceId }));
  }, []);

  return (
    <SignupLayout>
      <MainLoader />
    </SignupLayout>
  );
};

export default ValidationPage;
