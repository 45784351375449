import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import useTracker from '@common/hooks/useTracker';
import useUserSessionData from '@common/hooks/useUserSessionData';
import { TrackEvent } from '@common/utils/amplitude/events';
import OtpCodeForm, { OtpFormType } from '@monefit-ee/components/forms/OtpCodeForm';

const EmailOtpPage = () => {
  useTracker({
    initEventName: TrackEvent.EMAIL_OTP_PAGE_INIT,
    errorTrackEventName: TrackEvent.EMAIL_OTP_PAGE_ERROR,
  });
  const { getFromStorage } = useUserSessionData();

  return (
    <SignupLayout>
      <OtpCodeForm emailOrPhone={getFromStorage()?.email ?? ''} type={OtpFormType.EMAIL} />
    </SignupLayout>
  );
};

export default EmailOtpPage;
