import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useIntercom } from 'react-use-intercom';

import Button, { ButtonSpacing } from '@common/components/button/Button';
import { LINKS } from '@common/constants';
import { useCookieConsents } from '@common/hooks/useCookieConsents';

const useAuthenticationServiceErrorHandler = () => {
  const { consents, loaded: consentsLoaded } = useCookieConsents();
  const { showNewMessage } = useIntercom();
  const canOpenIntercom = consentsLoaded && consents.necessary;
  const { formatMessage } = useIntl();
  const handleOpenIntercom = useCallback(() => {
    showNewMessage();
  }, [showNewMessage]);

  const handleError = useCallback(
    (error: any) => {
      const code: number | null = error?.response?.data?.code ?? null;

      switch (code) {
        case 401: // OTP does not match the generated OTP.
        case 404: // No OTP challenge for the provided identifier.
          toast(formatMessage({ defaultMessage: 'Oops! The URL is invalid.' }), { type: 'error' });
          break;

        case 403: // OTP is used or has expired.
          toast(
            formatMessage({
              defaultMessage:
                'Oops! It looks like your login link has expired. Please enter your email address to request a new link. Remember, the link is valid for 15 minutes.',
              description:
                'Monefit-ES: Oops! It looks like your login link has expired. Please enter your email address to request a new link. Remember, the link is valid for 15 minutes.',
            }),
            { type: 'error' }
          );
          break;

        default: // Other cases
          toast(
            <div>
              {formatMessage({ defaultMessage: 'Oops! Something went wrong!' })} <br />{' '}
              {formatMessage({ defaultMessage: 'Help us improve your experience by sending an' })}{' '}
              {canOpenIntercom ? (
                <Button
                  onClick={handleOpenIntercom}
                  spacing={ButtonSpacing.NONE}
                  color="underlined-black"
                  text={formatMessage({ defaultMessage: 'error report' })}
                />
              ) : (
                <a className="underline" href={LINKS.ES_MAILTO}>
                  {formatMessage({ defaultMessage: 'error report' })}
                </a>
              )}
            </div>,
            { type: 'error' }
          );
      }
    },
    [canOpenIntercom, formatMessage, handleOpenIntercom]
  );

  return { handleError };
};

export default useAuthenticationServiceErrorHandler;
