import { TrackEvent } from '@common/utils/amplitude/events';

export interface FormFieldToTrackOnChange {
  fieldName: string;
  event: TrackEvent;
}

export const formatEEPhoneValue = (phone: string) => {
  return phone.replace(/\+372/g, '');
};

export const handleFormFieldsEventTracking = (
  values: any,
  fieldsToTrackOnChange: FormFieldToTrackOnChange[],
  trackEventHandler: (event: TrackEvent, properties?: Record<string, any> | undefined) => void
) => {
  for (const field of fieldsToTrackOnChange) {
    const key = field.fieldName;
    const value = values[key];
    value !== null && value !== undefined && trackEventHandler(field.event);
  }
};

export const emailValidationRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
