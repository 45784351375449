import { IconProps } from './types';

const CheckmarkIcon = ({ width = '37', height = '37' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.9663 15.0962C27.5464 14.5161 27.5464 13.5757 26.9663 12.9956C26.3863 12.4155 25.4458 12.4155 24.8657 12.9956L17.0039 20.8574L13.5982 17.4517C13.0181 16.8716 12.0776 16.8716 11.4975 17.4517C10.9175 18.0317 10.9175 18.9722 11.4975 19.5523L15.9536 24.0083C16.5337 24.5884 17.4741 24.5884 18.0542 24.0083L26.9663 15.0962Z"
        fill="#15171B"
      />
    </svg>
  );
};

export default CheckmarkIcon;
