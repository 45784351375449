import useTracker from '@common/hooks/useTracker';
import useUserSessionData from '@common/hooks/useUserSessionData';
import { TrackEvent } from '@common/utils/amplitude/events';
import SignupLayout from '@src/common/components/sign-up-layout/SignupLayout';
import OtpCodeForm, { OtpFormType } from '@src/monefit-ee/components/forms/OtpCodeForm';

const PhoneOtpPage = () => {
  useTracker({
    initEventName: TrackEvent.PHONE_OTP_PAGE_INIT,
    errorTrackEventName: TrackEvent.PHONE_OTP_PAGE_ERROR,
  });
  const { getFromStorage } = useUserSessionData();
  return (
    <SignupLayout>
      <OtpCodeForm emailOrPhone={getFromStorage()?.phone ?? ''} type={OtpFormType.PHONE} />
    </SignupLayout>
  );
};

export default PhoneOtpPage;
