import { ReactNode } from 'react';

import defaultLoaderSrc from '@src/assets/img/monefit-ee/main-loader.webp';
import classNames from 'classnames';

import LoadingIndicator from './LoadingIndicator';

interface LoadingCardProps {
  textNode?: ReactNode;
  text?: string;
  imgSrcProp?: string;
  showIndicator?: boolean;
  extraText?: string | ReactNode;
  extraClassNames?: string;
}

const LoadingCard = ({
  text,
  textNode,
  imgSrcProp,
  extraText,
  showIndicator = true,
  extraClassNames,
}: LoadingCardProps) => {
  return (
    <div className="flex h-96 w-full flex-col items-center justify-center gap-3 sm:size-96">
      <div
        className={classNames(
          'inline-flex size-full flex-col items-center justify-end gap-6 rounded-3xl bg-gradient-to-b from-black to-black p-7 backdrop-blur-2xl',
          extraClassNames
        )}
        style={{
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${imgSrcProp ?? defaultLoaderSrc})`,
        }}
      >
        <div className="flex h-28 flex-col items-center justify-end gap-2.5 self-stretch">
          <div
            className="mb-1 text-center text-2xl font-medium leading-[34px] text-white"
            style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}
          >
            {textNode ?? text ?? ''}
          </div>
          {showIndicator && <LoadingIndicator />}
        </div>
      </div>
      {!!extraText && (
        <div className="text-center text-[17px] font-semibold leading-normal text-stone-700">
          {extraText}
        </div>
      )}
    </div>
  );
};

export default LoadingCard;
