import { ReactNode, useMemo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import MetaTags from '@common/components/MetaTags';
import { useAppSelector } from '@monefit-es/hooks';
import classNames from 'classnames';

import Footer from '../footer/Footer';
import Header from '../header/Header';
import MainLoader from '../loaders/MainLoader';

const MainLayout = ({
  children,
  userDisplayNameNode,
  hideHeaderAndFooter = false,
  showLoader = true,
}: {
  children: ReactNode;
  userDisplayNameNode?: ReactNode;
  hideHeaderAndFooter?: boolean;
  showLoader?: boolean;
}) => {
  const { loading: authenticationLoading } = useAppSelector((st) => st.authentication);
  const { formatMessage } = useIntl();
  const isLoading = useMemo(() => authenticationLoading, [authenticationLoading]);

  return (
    <>
      <HelmetProvider>
        <MetaTags
          title="Monefit Credit"
          description={formatMessage({
            defaultMessage: 'Interest-free creditline',
            description: 'Interest-free creditline',
          })}
        />
      </HelmetProvider>
      <div
        className={classNames(
          'flex h-screen flex-col gap-6 bg-stone-50 p-2 md:p-12',
          hideHeaderAndFooter ? 'justify-center' : 'justify-between'
        )}
      >
        {!hideHeaderAndFooter && <Header userDisplayNameNode={userDisplayNameNode} />}
        {isLoading && showLoader ? (
          <MainLoader />
        ) : (
          <div className="flex w-full items-center justify-center">{children}</div>
        )}
        {!hideHeaderAndFooter && <Footer />}
      </div>
    </>
  );
};

export default MainLayout;
