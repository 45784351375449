import { IconProps } from '../types';

const XIcon = ({ isBlackAndWhite }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none">
      <g clipPath="url(#clip0_1784_558)">
        <path
          d="M25.6152 18.1711L41.5917 0H37.8071L23.9289 15.7745L12.8526 0H0.0742188L16.8274 23.8561L0.0742188 42.909H3.85881L18.5051 26.247L30.2051 42.909H42.9834M5.22475 2.79248H11.0389L37.8043 40.2538H31.9886"
          fill="black"
          fillOpacity={isBlackAndWhite ? '48%' : '100%'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1784_558">
          <rect width="42.9092" height="42.9092" fill="white" transform="translate(0.0742188)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default XIcon;
