import { ReactNode } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Country } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';

import InfoIconCircle from '@common/components/icons/InfoIconCircle';

import InputLabel from '../InputLabel';

interface PhoneInputProps {
  name: string;
  country?: Country;
  label?: string;
  required?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  description?: string;
  descriptionNode?: ReactNode;
}

const PhoneInput = ({
  name,
  country,
  label,
  required,
  defaultValue,
  disabled,
  description,
  descriptionNode,
}: PhoneInputProps) => {
  const {
    field: { onChange, value },
    formState: { isSubmitting },
    fieldState: { error },
  } = useController({
    defaultValue: defaultValue,
    name,
    rules: { required: required },
  });

  const { trigger } = useFormContext();

  return (
    <div className="relative">
      <Input
        international
        withCountryCallingCode
        disabled={isSubmitting || disabled}
        value={value}
        className={`h-16 w-full rounded-xl border border-gray-300 px-7 font-semibold 
            placeholder:font-normal placeholder:text-gray-400 focus:outline-none ${
              label ? 'pt-3' : ''
            } `}
        country={country}
        onBlur={() => {
          trigger(name);
        }}
        onChange={onChange}
      />
      {!!label && <InputLabel name={name} text={label} shouldMinimize />}
      {!!error?.message && (
        <div className="mt-2 text-xs font-semibold text-rose-400">{error?.message?.toString()}</div>
      )}
      {!!description && (
        <div className="mt-2 flex gap-2 text-[14px] font-normal text-black">
          <div>
            <InfoIconCircle />
          </div>
          {description}
        </div>
      )}
      {!!descriptionNode && descriptionNode}
    </div>
  );
};

export default PhoneInput;
