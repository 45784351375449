import { ReactNode } from 'react';

import classNames from 'classnames';

const SignupBaseCard = ({
  children,
  extraClassNames = '',
  headerText,
  subHeaderText,
  headerExtraClassNames,
}: {
  children: ReactNode;
  headerText?: string;
  subHeaderText?: string;
  extraClassNames?: string;
  headerExtraClassNames?: string;
}) => {
  return (
    <div
      className={`inline-flex w-full flex-col items-start justify-start gap-12 rounded-3xl bg-white px-[13px] py-7 sm:w-[610px] sm:p-12 ${extraClassNames}`}
    >
      <div className="flex flex-col items-start justify-start gap-3.5 self-stretch pt-2">
        {!!headerText && (
          <div
            className={classNames(
              headerExtraClassNames,
              'self-stretch text-2xl font-semibold leading-tight tracking-normal text-stone-950'
            )}
          >
            {headerText}
          </div>
        )}
        {!!subHeaderText && (
          <div className="self-stretch text-lg font-medium leading-normal text-black">
            {subHeaderText}
          </div>
        )}
      </div>
      {children ?? <></>}
    </div>
  );
};

export default SignupBaseCard;
