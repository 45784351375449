export type ErrorName = 'api' | 'generic' | 'page-not-found' | 'missing-implementation';

export class BaseError extends Error {
  readonly name: ErrorName = 'generic';
}

export class ApiError extends Error {
  readonly name: ErrorName = 'api';
}

export class PageNotFoundError extends BaseError {
  readonly name: ErrorName = 'page-not-found';
}

export class MissingImplementationError extends BaseError {
  readonly name: ErrorName = 'missing-implementation';
}
