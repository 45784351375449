import { reducers as commonReducers } from '@common/redux/root.reducer';
import authentication from '@common/redux/slices/authentication';
import { combineReducers } from '@reduxjs/toolkit';

export const reducers = {
  ...commonReducers,
  authentication,
};

export const rootReducer = combineReducers(reducers);

export type RootState = ReturnType<typeof rootReducer>;
