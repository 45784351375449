import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Routes } from 'react-router-dom';

import { useLocale } from '@common/hooks';
import { PageNotFoundError } from '@common/utils';
import AddressForm from '@monefit-es/components/forms/AddressForm';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import { ErrorBoundaryPage, SingupPage } from '@monefit-es/pages';
import LoginPage from '@monefit-es/pages/login-page/LoginPage';
import WaitingListPage from '@monefit-es/pages/waiting-list-page/WaitingListPage';

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';

const Router = () => {
  const pageNotFoundError = useMemo(() => new PageNotFoundError(), []);
  const { allowedLocales, localeWithCountry } = useLocale();
  if (!allowedLocales) {
    // Show blank page with loading message until allowedLocales (or fallback locales) are defined
    return (
      <FormattedMessage defaultMessage="Loading..." description="ErrorBoundaryPage: Loading" />
    );
  }

  const { REACT_APP_ENVIRONMENT } = process.env;

  return (
    <Routes>
      <Route
        path={`${localeWithCountry}/sign-up`}
        element={
          <PublicRoute>
            <SingupPage />
          </PublicRoute>
        }
      />
      <Route
        path={`${localeWithCountry}/login`}
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route
        path={`${localeWithCountry}/user`}
        element={
          <PrivateRoute hasMainLayout={false}>
            <WaitingListPage />
          </PrivateRoute>
        }
      />
      {/* TODO: Remove when is tested. Storybook is blocking fetchify requests for some reason, that's why was added here */}
      {['development', 'staging'].includes(REACT_APP_ENVIRONMENT) && (
        <Route
          path={`${localeWithCountry}/test/address`}
          element={
            <MainLayout>
              <AddressForm />
            </MainLayout>
          }
        />
      )}
      {/* <Route
        path={`${localeWithCountry}/user/profile`}
        element={
          <PrivateRoute>
            <>User profile page</>
          </PrivateRoute>
        }
      />
      <Route
        path={`${localeWithCountry}/user/:flowId`}
        element={
          <PrivateRoute>
            <>Private Flow page</>
          </PrivateRoute>
        }
      />
      <Route
        path={`${localeWithCountry}/:flowId`}
        element={
          <MainLayout>
            <>Public flow page</>
          </MainLayout>
        }
      /> */}
      <Route path="*" element={<ErrorBoundaryPage error={pageNotFoundError} />} />
    </Routes>
  );
};

export default Router;
