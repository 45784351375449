import { ReactNode, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface CheckboxProps {
  name: string;
  label?: string;
  disabled?: boolean;
  labelComponent?: ReactNode;
}

const Checkbox = ({ name, label, disabled, labelComponent }: CheckboxProps) => {
  useController({
    name,
    rules: {},
  });

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useFormContext();

  const { onChange, ...registration } = register(name);

  const isChecked = !!getValues(name);

  const handleLabelClick = useCallback(() => {
    const currValue = getValues(name);
    setValue(name, !currValue);
    trigger(name);
  }, [trigger, setValue, getValues, name]);

  return (
    <div className="flex flex-col">
      <div className="inline-flex w-full items-start justify-start gap-5">
        <label className="flex cursor-pointer items-center gap-4">
          <input
            type="checkbox"
            className="hidden"
            checked={isChecked}
            {...registration}
            onChange={(e) => {
              onChange(e);
              trigger(name);
            }}
            disabled={disabled}
          />
          <div
            className="flex size-8 rotate-180 items-center justify-center rounded-lg 
            border border-gray-300 bg-white 
            transition duration-300 ease-in-out"
          >
            {isChecked && <div className="size-5 rounded-full bg-blue-brand"></div>}
          </div>
        </label>
        <>
          {labelComponent ? (
            labelComponent
          ) : (
            <div
              role="presentation"
              onClick={handleLabelClick}
              className="shrink grow basis-0 cursor-pointer text-[15px] font-medium leading-normal text-black"
            >
              {label ?? ''}
            </div>
          )}
        </>
      </div>
      {!!errors?.[name]?.message && (
        <div className="mt-2 text-xs font-semibold text-rose-400">
          {errors?.[name]?.message?.toString()}
        </div>
      )}
    </div>
  );
};

export default Checkbox;
