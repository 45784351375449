import { DEV_PUBLIC_URL, PRODUCTION_PUBLIC_URL, STAGING_PUBLIC_URL } from '@common/constants';

export const getPublicUrl = (env: 'development' | 'staging' | 'production') => {
  switch (env) {
    case 'development':
      return DEV_PUBLIC_URL;
    case 'staging':
      return STAGING_PUBLIC_URL;
    case 'production':
      return PRODUCTION_PUBLIC_URL;
    default:
      return DEV_PUBLIC_URL;
  }
};
