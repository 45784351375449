import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { isArrayOfStringErrors } from '@common/utils/helpers';
import { useAppSelector } from '@monefit-ee/hooks';

interface Error {
  [key: string]: string[] | string;
}

const useError = () => {
  const { data } = useAppSelector((state) => state.application.flowInstance);
  const errors = data?.errors;
  const { formatMessage } = useIntl();
  const fallbackMessage = formatMessage({
    defaultMessage:
      'An error occurred while processing the request. Please contact us if you believe this is a server error. Thank you.',
    description: 'ErrorBoundaryPage: Error fallbackMessage',
  });

  const errorsResponse: string[] = useMemo(() => {
    if (typeof errors === 'object' && !Array.isArray(errors)) {
      const typedErrorObject = (errors as Error) || {};
      const errorsRaw = Object.values(typedErrorObject);
      if (isArrayOfStringErrors(errorsRaw)) {
        return errorsRaw;
      }
      const resultErrors = [];
      for (const categorizedErrors of errorsRaw) {
        for (const errorString of categorizedErrors) {
          resultErrors.push(errorString);
        }
      }
      return resultErrors;
    } else if (Array.isArray(errors)) {
      return errors;
    } else {
      return [];
    }
  }, [errors]);

  const notifyErrors = useCallback(() => {
    errorsResponse
      .filter((x) => x !== 'Undefined action.')
      .map((error) =>
        toast(error || fallbackMessage, { type: 'error', toastId: error || fallbackMessage })
      );
  }, [errorsResponse, fallbackMessage]);

  const notifyError = useCallback(
    (message?: string) => {
      toast(message || fallbackMessage, { type: 'error', toastId: fallbackMessage });
    },
    [fallbackMessage]
  );

  return {
    errors: errorsResponse,
    notifyErrors,
    notifyError,
  };
};

export default useError;
