import { useCallback, useMemo } from 'react';

import { LocalStorageKeys } from '@common/constants';
import { useApplicationFlowInstance } from '@common/hooks';
import { IdentificationProvider } from '@common/services/application';

interface Identification {
  code?: string;
  provider: IdentificationProvider;
}

const usePendingIdentification = () => {
  const [instanceId] = useApplicationFlowInstance();
  const storageKey = `${LocalStorageKeys.IDENTIFICATION}_${instanceId}`;

  const pendingIdentification = useMemo(() => {
    if (!instanceId) {
      return null;
    }
    const rawData = sessionStorage.getItem(storageKey);
    return rawData ? (JSON.parse(rawData) as Identification) : null;
  }, [storageKey, instanceId]);

  const saveToIdentificationStorage = useCallback(
    (data: Identification) => {
      if (!instanceId) {
        return;
      }
      sessionStorage.setItem(storageKey, JSON.stringify(data));
    },
    [storageKey, instanceId]
  );

  const clearIdentificationStorage = useCallback(() => {
    if (!instanceId) {
      return;
    }
    sessionStorage.removeItem(storageKey);
  }, [instanceId, storageKey]);

  return {
    pendingIdentification,
    saveToIdentificationStorage,
    clearIdentificationStorage,
  };
};

export default usePendingIdentification;
