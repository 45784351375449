export function getCoreServiceBaseUrls() {
  const baseUrl = process.env.REACT_APP_CORE_SERVICE_BASEURL;
  const authenticationBaseUrl = `${baseUrl}/authentication/v1`;
  const applicationBaseUrl = `${baseUrl}/application/v1`;
  const bankStatementBaseUrl = `${baseUrl}/bank-statement/v1`;

  return {
    authenticationBaseUrl,
    applicationBaseUrl,
    bankStatementBaseUrl,
  };
}
