import {
  AuthorizeResponse,
  LinkBasedLoginResponse,
  LinkBasedRegisterResponse,
  OAuthGetAuthUrlResponse,
} from '@common/services/authentication';
import { createSlice } from '@reduxjs/toolkit';

import {
  linkBasedAuthorize,
  linkBasedLogin,
  linkBasedRegister,
  oAuthAuthorize,
  oAuthGetAuthorizationUrl,
} from '../thunks/authentication';

interface AuthState {
  loading: boolean;
  error: string | null;
  data:
    | LinkBasedRegisterResponse
    | OAuthGetAuthUrlResponse
    | AuthorizeResponse
    | LinkBasedLoginResponse
    | null;
}

const initialState: AuthState = {
  loading: false,
  error: null,
  data: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    // Link based register builders
    builder.addCase(linkBasedRegister.fulfilled, (state, action) => {
      const { identifier, displayName } = action.meta.arg;
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          ...action.payload,
          identifier,
          displayName,
        },
      };
    });

    builder.addCase(linkBasedRegister.rejected, (state, action) => ({
      ...state,
      loading: false,
      error: action.error?.message ?? '',
    }));

    builder.addCase(linkBasedRegister.pending, (state) => ({
      ...state,
      loading: true,
      error: null,
    }));

    // Link based login builders
    builder.addCase(linkBasedLogin.fulfilled, (state, action) => {
      const { identifier } = action.meta.arg;
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          ...action.payload,
          identifier,
        },
      };
    });

    builder.addCase(linkBasedLogin.rejected, (state, action) => ({
      ...state,
      loading: false,
      error: action.error?.message ?? '',
    }));

    builder.addCase(linkBasedLogin.pending, (state) => ({
      ...state,
      loading: true,
      error: null,
    }));

    // Get oauth url builders
    builder.addCase(oAuthGetAuthorizationUrl.fulfilled, (state, action) => ({
      ...state,
      loading: true,
      error: null,
      data: {
        ...action.payload,
      },
    }));

    builder.addCase(oAuthGetAuthorizationUrl.rejected, (state, action) => ({
      ...state,
      loading: false,
      error: action.error?.message ?? '',
    }));

    builder.addCase(oAuthGetAuthorizationUrl.pending, (state) => ({
      ...state,
      loading: true,
      error: null,
    }));

    // Get oauth authorize builders
    builder.addCase(oAuthAuthorize.fulfilled, (state, action) => ({
      ...state,
      loading: false,
      error: null,
      data: {
        ...action.payload,
      },
    }));

    builder.addCase(oAuthAuthorize.rejected, (state, action) => ({
      ...state,
      loading: false,
      error: action.error?.message ?? '',
    }));

    builder.addCase(oAuthAuthorize.pending, (state) => ({
      ...state,
      loading: true,
      error: null,
    }));

    // Link based authorize builders
    builder.addCase(linkBasedAuthorize.fulfilled, (state, action) => ({
      ...state,
      loading: false,
      error: null,
      data: {
        ...action.payload,
      },
    }));

    builder.addCase(linkBasedAuthorize.rejected, (state, action) => ({
      ...state,
      loading: false,
      error: action.error?.message ?? '',
    }));

    builder.addCase(linkBasedAuthorize.pending, (state) => ({
      ...state,
      loading: true,
      error: null,
    }));
  },
});

export const { setLoading } = authSlice.actions;

export default authSlice.reducer;
