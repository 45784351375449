const ProtectedIcon = () => {
  return (
    <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 6.03285C0.5 5.66968 0.696892 5.33506 1.01436 5.15869L8.01436 1.2698C8.31638 1.10201 8.68362 1.10201 8.98564 1.2698L15.9856 5.15869C16.3031 5.33506 16.5 5.66968 16.5 6.03285V10.6715C16.5 14.3032 14.5311 17.6494 11.3564 19.4131L8.98564 20.7302C8.68362 20.898 8.31638 20.898 8.01436 20.7302L5.64357 19.4131C2.46892 17.6494 0.5 14.3032 0.5 10.6715V6.03285Z"
        stroke="black"
        strokeOpacity="0.48"
      />
      <path
        d="M6 11L8 13L12 9"
        stroke="black"
        strokeOpacity="0.48"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProtectedIcon;
