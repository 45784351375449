import { LocalStorageKeys } from '@common/constants';
import { SubStep } from '@common/services/application';

export const saveBankSubStepToStorage = (instanceId: string | null, subStep: SubStep) => {
  sessionStorage.setItem(`${LocalStorageKeys.BANK_FLOW_SUBSTEP}_${instanceId}`, subStep);
};

export const getBankSubStepFromStorage = (instanceId: string | null) => {
  if (!instanceId) {
    return null;
  }
  return sessionStorage.getItem(`${LocalStorageKeys.BANK_FLOW_SUBSTEP}_${instanceId}`) ?? null;
};

export const removeBankSubStepFromStorage = (instanceId: string | null) => {
  return sessionStorage.removeItem(`${LocalStorageKeys.BANK_FLOW_SUBSTEP}_${instanceId}`);
};
