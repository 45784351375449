import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useApplicationFlowInstance } from '@common/hooks';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { useAppDispatch, useAppSelector } from '@monefit-ee/hooks';

import Button from './Button';

const BackButton = () => {
  const dispatch = useAppDispatch();
  const { data, loading } = useAppSelector((st) => st.application.flowInstance);
  const [instanceId] = useApplicationFlowInstance();
  const { formatMessage } = useIntl();

  const handleBackClick = useCallback(() => {
    dispatch(performApplicationFlowAction({ action: FlowActions.BACK, instanceId }));
  }, [dispatch, instanceId]);

  return data?.allowedBackStepId ? (
    <Button
      fullWidth
      type="button"
      disabled={loading}
      color="blue"
      text={formatMessage({ defaultMessage: 'Back', description: 'Back button text' })}
      onClick={handleBackClick}
    />
  ) : null;
};

export default BackButton;
