import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import '@common/components/button/Button';
import Form from '@common/components/form/Form';
import { useApplicationFlowInstance } from '@common/hooks';
import { setCurrentSubstepId } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions, SubStep } from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';
import { useAppDispatch } from '@monefit-ee/hooks';
import { saveBankSubStepToStorage } from '@monefit-ee/pages/bank-page/bankPageHelpers';
import img from '@src/assets/img/monefit-ee/consent-page-img.webp';

interface FormValues {
  bankVerificationConsent: boolean;
}

const ConsentForm = () => {
  const dispatch = useAppDispatch();
  const [instanceId] = useApplicationFlowInstance();

  const handleSubmit = useCallback(
    (values: FormValues) => {
      dispatch(
        performApplicationFlowAction({
          action: FlowActions.SUBMIT,
          instanceId,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(setCurrentSubstepId(SubStep.BANK_IN_PROGRESS));
          saveBankSubStepToStorage(instanceId, SubStep.BANK_IN_PROGRESS);
        });
    },
    [dispatch, instanceId]
  );

  return (
    <div className="flex w-full max-w-md flex-col items-center gap-3 sm:w-96 sm:max-w-none">
      <Form
        gapClass="gap-5"
        //schema={validationSchema}
        onSubmit={handleSubmit}
        submitTrackEventName={TrackEvent.CONSENT_PAGE_SUBMIT}
        fieldsToTrackOnChange={[
          { fieldName: 'bankVerificationConsent', event: TrackEvent.CONSENT_PAGE_CHECKBOX_SELECT },
        ]}
      >
        <div
          style={{
            backgroundImage: `linear-gradient(
            rgba(0, 0, 0, 0.25),
            rgba(0, 0, 0, 0.25)
          ), url(${img})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
          className="inline-flex h-96  flex-col items-center justify-end gap-6 rounded-3xl px-2 py-7 sm:p-6"
        >
          <div
            className="text-center text-2xl font-medium leading-[34px] text-white"
            style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}
          >
            <FormattedMessage
              defaultMessage="Confirm your income and expenses."
              description="Bank: 'Confirm your income and expenses.'"
            />
          </div>
        </div>
      </Form>

      <div className="text-center text-[15px] font-normal leading-tight text-black text-opacity-60">
        <FormattedMessage
          defaultMessage="We need to verify your income and expenses by reviewing your bank statements."
          values={{
            link: (
              <a className="underline" rel="noreferrer" target="_blank" href="https://fi.ee/et">
                <FormattedMessage
                  defaultMessage="Financial Supervision Authority"
                  description='Bank: "Financial Supervision Authority"'
                />
              </a>
            ),
          }}
        />
      </div>
      <div className="text-center text-[15px] font-normal leading-tight text-black text-opacity-60">
        <FormattedMessage defaultMessage="Using our secure app, we'll quickly view your transactions, and nothing more - it's safe and easy!" />
      </div>
    </div>
  );
};

export default ConsentForm;
