import { useCallback, useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { toast } from 'react-toastify';

import InputLabel from '@common/components/form/inputs/InputLabel';
import { IOption } from '@common/components/form/inputs/selectInput/SelectInput';
import useDebouncedValue from '@common/hooks/useDebouncedValue';
import axios from 'axios';

import { FetchifyAddressRetrieve, FetchifyAddressesFetch } from './types';

const FETCHIFY_BASE_URL = 'https://api.craftyclicks.co.uk/address/1.1';

interface SelectValue {
  label: string;
  value: string;
}

interface AddressInputProps {
  name: string;
  label: string;
  hideError?: boolean;
}

const AddressInput = ({ name, label, hideError }: AddressInputProps) => {
  const { formatMessage } = useIntl();
  const [inputValue, setInputValue] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const debouncedInputValue = useDebouncedValue(inputValue, 1000);

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  const {
    field: { value },
    formState: { isSubmitting },
  } = useController({
    name,
  });

  const {
    formState: { errors },

    trigger,
    setValue,
  } = useFormContext();

  const loadAddressOptions = useCallback(
    async (value: string) => {
      const { REACT_APP_FETCHIFY_API_KEY } = process.env;

      const payload = {
        key: REACT_APP_FETCHIFY_API_KEY,
        query: value,
        country: 'es',
        extra: {
          exclude_pobox: true,
          no_groupings: true,
        },
      };

      try {
        setOptionsLoading(true);
        axios.post<FetchifyAddressesFetch>(`${FETCHIFY_BASE_URL}/find`, payload).then((res) => {
          const optionsList = res?.data?.results ?? [];
          const optionsMapped = optionsList.map((x) => ({
            value: x.id,
            label: x.labels.join(', '),
          }));
          setOptions(optionsMapped);
        });
      } catch (_) {
        toast(formatMessage({ defaultMessage: 'Unbale to fetch addresses' }), { type: 'error' });
      } finally {
        setOptionsLoading(false);
      }
    },
    [formatMessage]
  );

  useEffect(() => {
    if (debouncedInputValue.length >= 3) {
      loadAddressOptions(debouncedInputValue);
    } else {
      setOptions([]);
    }
  }, [debouncedInputValue, loadAddressOptions]);

  useEffect(() => {
    setMenuIsOpen(debouncedInputValue.length >= 3);
  }, [debouncedInputValue]);

  const handleChange = useCallback(
    async (newValue: SelectValue | null) => {
      if (!newValue?.value) {
        setValue(name, undefined);
        return;
      }

      const { REACT_APP_FETCHIFY_API_KEY } = process.env;
      const payload = {
        key: REACT_APP_FETCHIFY_API_KEY,
        id: newValue.value,
        country: 'es',
      };

      try {
        const res = await axios.post(`${FETCHIFY_BASE_URL}/retrieve`, payload);
        const typedRes = res?.data?.result as FetchifyAddressRetrieve;

        const address = {
          region: typedRes?.province_name,
          locality: typedRes?.locality,
          street: typedRes?.street_prefix + typedRes?.street_name + typedRes?.street_suffix,
          postcode: typedRes?.postal_code,
          houseNo: typedRes?.building_number,
          houseName: typedRes?.building_name,
        };
        setValue(name, address);
      } catch (e) {
        console.error(e);
        toast(formatMessage({ defaultMessage: 'Unable to retrieve address' }), { type: 'error' });
      }
    },
    [name, setValue, formatMessage]
  );

  return (
    <div className="relative">
      <Select
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
        filterOption={null}
        options={options}
        inputValue={inputValue}
        isLoading={optionsLoading}
        onInputChange={handleInputChange}
        menuIsOpen={menuIsOpen}
        isClearable
        isDisabled={isSubmitting}
        loadingMessage={() => formatMessage({ defaultMessage: 'Loading' })}
        noOptionsMessage={() =>
          optionsLoading
            ? formatMessage({ defaultMessage: 'Loading' })
            : formatMessage({ defaultMessage: 'No options' })
        }
        onMenuClose={() => setMenuIsOpen(false)}
        classNames={{
          control: () => 'h-16',
          menu: () => 'px-3 py-2 sm:px-7 sm:py-4',
          valueContainer: () => 'px-8 py-7',
        }}
        styles={{
          control: (base, props): any => ({
            ...base,
            borderColor: '#d1d5db',
            boxShadow: 'none',
            borderRadius: '0.75rem', // rounded-xl
            '&:hover': {
              borderColor: '#d1d5db', // gray-300
            },
          }),
          option: (base, state): any => ({
            ...base,
            cursor: 'pointer',
            color: 'rgba(0, 0, 0, 0.48)',
            padding: '5px 1px',
            backgroundColor: 'transparent',
            fontWeight: state.isFocused ? 'bold' : 'normal',
            '&:active': {
              backgroundColor: 'white',
            },
            '&:hover': {
              backgroundColor: '#F3F4F6', // gray-100
              borderRadius: '0.125rem', // rounded-sm,
            },
          }),
          input: (base) => ({
            ...base,
          }),
          menu: (base): any => ({
            ...base,
            borderRadius: '0.75rem', // rounded-xl,
            border: '1px solid #d1d5db', // border-gray-300
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
            marginTop: '4px',
            marginBottom: '4px',
          }),
          valueContainer: (base): any => ({
            ...base,
            display: 'flex',
            paddingTop: '13px', // pt-3
            paddingLeft: '24px', // pl-6
            fontWeight: '600',
            flexWrap: 'no-wrap',
          }),
        }}
        placeholder=""
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          trigger(name);
        }}
        onChange={handleChange as any}
      />
      <div>
        <InputLabel name={name} text={label} shouldMinimize={isFocused || value !== undefined} />
      </div>
      {!!errors?.[name]?.message && !hideError && (
        <div className="mt-2 text-xs font-semibold text-rose-400">
          {errors?.[name]?.message?.toString()}
        </div>
      )}
    </div>
  );
};

export default AddressInput;
