import { ReactAppId, useBrand } from './useBrand';

export enum Country {
  EE = 'ee',
  ES = 'es',
}

// TODO: should not be hardcored here but queried from BE instead (?)
export const ALLOWED_COUNTRIES = {
  [ReactAppId.MONEFIT]: [Country.EE, Country.ES],
  [ReactAppId.CREDITSTAR]: [Country.ES],
};

const useCountry = () => {
  const { brand } = useBrand();
  const localeFromUrl = window.location.pathname.split('/').slice(1)[0];
  const countryFromUrl = (localeFromUrl.split('-')?.[1] as Country) ?? null; // et-ee -> ee; en-es -> es ..etc
  const allowedCountries = brand && ALLOWED_COUNTRIES[brand] ? ALLOWED_COUNTRIES[brand] : [];
  const country =
    allowedCountries.indexOf(countryFromUrl) === -1 ? null : (countryFromUrl as Country);

  return { country };
};

export default useCountry;
