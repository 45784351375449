import FacebookIcon from '@common/components/icons/social-media/FacebookIcon';
import InstagramIcon from '@common/components/icons/social-media/InstagramIcon';
import LinkedIn from '@common/components/icons/social-media/LinkedIn';
import XIcon from '@common/components/icons/social-media/XIcon';
import YoutubeIcon from '@common/components/icons/social-media/YoutubeIcon';
import { LINKS } from '@common/constants';

import LinkButton from './LinkButton';

const FooterLinks = () => {
  return (
    <div className="mb-12 flex justify-center gap-4 sm:gap-10">
      <LinkButton
        link={LINKS.ES_FACEBOOK}
        defatultIcon={<FacebookIcon isBlackAndWhite />}
        coloredIcon={<FacebookIcon />}
      />
      <LinkButton
        link={LINKS.INSTAGRAM}
        defatultIcon={<InstagramIcon isBlackAndWhite />}
        coloredIcon={<InstagramIcon />}
      />
      <LinkButton
        link={LINKS.YOUTUBE}
        defatultIcon={<YoutubeIcon isBlackAndWhite />}
        coloredIcon={<YoutubeIcon />}
      />
      <LinkButton
        link={LINKS.LINKEDIN}
        defatultIcon={<LinkedIn isBlackAndWhite />}
        coloredIcon={<LinkedIn />}
      />
      <LinkButton link={LINKS.X} defatultIcon={<XIcon isBlackAndWhite />} coloredIcon={<XIcon />} />
    </div>
  );
};

export default FooterLinks;
