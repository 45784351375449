import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import CheckmarkIcon from '@common/components/icons/CheckmarkIcon';
import EyeIcon from '@common/components/icons/EyeIcon';
import Modal from '@common/components/modal/Modal';
import useTracker from '@common/hooks/useTracker';
import { TrackEvent } from '@common/utils/amplitude/events';

interface DocumentCardProps {
  title: string;
  documentContent: string;
  isDocumentAccepted: boolean;
  onDocumentAccept: () => void;
  trackEventRead: string;
  trackEventAccept: string;
}

const DocumentCard = ({
  title,
  documentContent,
  isDocumentAccepted,
  onDocumentAccept,
  trackEventRead,
  trackEventAccept,
}: DocumentCardProps) => {
  const [showModal, setShowModal] = useState(false);
  const { trackEvent } = useTracker({});
  const { formatMessage } = useIntl();

  const handleAccept = useCallback(() => {
    onDocumentAccept();
    trackEvent(trackEventAccept as TrackEvent);
    setShowModal(false);
  }, [setShowModal, onDocumentAccept, trackEvent, trackEventAccept]);

  return (
    <>
      <div className="inline-flex h-64 w-full flex-col items-start justify-between rounded-3xl bg-white px-6 py-7 backdrop-blur-2xl sm:h-80 sm:p-12">
        <div className="flex flex-col items-start justify-start gap-5 self-stretch">
          <div className="inline-flex items-start justify-start gap-5 self-stretch">
            <div className="inline-flex flex-col items-start justify-start gap-2.5">
              <div className="text-lg font-semibold leading-normal text-black">{title}</div>
            </div>
          </div>
        </div>
        {isDocumentAccepted ? (
          <Button
            text={formatMessage({ defaultMessage: 'Signed', description: 'Documents: "Signed"' })}
            color="green"
            textSize="xl"
            fullWidth
            disabled
            icon={<CheckmarkIcon width="27" height="27" />}
          />
        ) : (
          <Button
            onClick={() => {
              setShowModal(true);
              trackEvent(trackEventRead as TrackEvent);
            }}
            text={formatMessage({ defaultMessage: 'Read', description: 'Documents: "Read"' })}
            color="gray"
            textSize="xl"
            fullWidth
            icon={<EyeIcon width="27" height="27" />}
          />
        )}
      </div>
      <Modal
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        onBackClick={() => setShowModal(false)}
      >
        <div className="flex flex-col gap-3">
          <div dangerouslySetInnerHTML={{ __html: documentContent }} />
          <Button
            onClick={handleAccept}
            type="button"
            text={formatMessage({ defaultMessage: 'Accept', description: 'Documents: "Accept"' })}
            color="gray"
            icon={<CheckmarkIcon width="27" height="27" />}
          />
        </div>
      </Modal>
    </>
  );
};

export default DocumentCard;
