import { IconProps } from './types';

const LogoIcon = ({ width = '70', height = '70' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.4375" y="0.835938" width="69.0513" height="69.0513" rx="34.5256" fill="black" />
      <path
        d="M19.0275 46.1656C18.8321 45.9708 18.7344 45.7331 18.7344 45.4536V25.6882C18.7344 25.4094 18.8321 25.1717 19.0275 24.9762C19.2223 24.7813 19.46 24.6831 19.7395 24.6831H23.0059C23.2841 24.6831 23.5218 24.7813 23.7179 24.9762C23.9127 25.1717 24.011 25.4094 24.011 25.6882V27.1117C25.4618 25.2133 27.4865 24.2642 30.0831 24.2642C33.182 24.2642 35.3449 25.5065 36.5734 27.9911C37.2437 26.8751 38.1923 25.9745 39.4215 25.29C40.6495 24.6065 41.9757 24.2642 43.3998 24.2642C45.6884 24.2642 47.5593 25.0459 49.0113 26.6093C50.4627 28.1728 51.1885 30.4483 51.1885 33.4351V45.4536C51.1885 45.7331 51.0976 45.9708 50.9165 46.1656C50.7348 46.361 50.4908 46.4587 50.1838 46.4587H46.7495C46.4707 46.4587 46.233 46.361 46.0375 46.1656C45.8421 45.9708 45.745 45.7331 45.745 45.4536V33.77C45.745 32.0111 45.3747 30.734 44.6353 29.9385C43.8952 29.1425 42.9112 28.7448 41.6826 28.7448C40.5941 28.7448 39.6654 29.1499 38.898 29.9591C38.13 30.7694 37.746 32.0397 37.746 33.77V45.4536C37.746 45.7331 37.6483 45.9708 37.4535 46.1656C37.2574 46.361 37.0203 46.4587 36.7414 46.4587H33.3071C33.0282 46.4587 32.7905 46.361 32.5957 46.1656C32.3996 45.9708 32.3025 45.7331 32.3025 45.4536V33.77C32.3025 32.0111 31.9186 30.734 31.1505 29.9385C30.3825 29.1425 29.4127 28.7448 28.2401 28.7448C27.1236 28.7448 26.1813 29.1499 25.4139 29.9591C24.6452 30.7694 24.2619 32.0397 24.2619 33.77V45.4536C24.2619 45.7331 24.1642 45.9708 23.9687 46.1656C23.7733 46.361 23.5356 46.4587 23.2573 46.4587H19.7395C19.46 46.4587 19.2223 46.361 19.0275 46.1656Z"
        fill="#FAFAF4"
      />
    </svg>
  );
};

export default LogoIcon;
