import { useEffect } from 'react';

import { MONEFIT_PUBLIC_SITE_URL } from '@common/constants';

const CountryNotFoundRedirectPage = () => {
  useEffect(() => {
    window.location.replace(MONEFIT_PUBLIC_SITE_URL);
  }, []);

  return <></>;
};

export default CountryNotFoundRedirectPage;
