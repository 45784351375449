import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import Button from '@common/components/button/Button';
import { useAppSelector } from '@monefit-es/hooks';
import useAuthenticationServiceErrorHandler from '@monefit-es/hooks/useAuthenticationServiceErrorHandler';
import dots from '@src/assets/amimations/Dots-animation-black.json';
import envelopeAnimation from '@src/assets/amimations/Envelope.json';
import { AxiosError } from 'axios';
import Lottie from 'lottie-react';

const CheckEmailCard = ({
  email,
  handleResend,
}: {
  email: string;
  handleResend: () => Promise<void>;
}) => {
  const { formatMessage } = useIntl();
  const { loading } = useAppSelector((st) => st.authentication);
  const [animationKey, setAnimationKey] = useState(0); // State variable to trigger animation replay
  const { handleError } = useAuthenticationServiceErrorHandler();
  const replayAnimation = useCallback(() => {
    setAnimationKey((prevKey) => prevKey + 1);
  }, []);

  const resendEmail = useCallback(async () => {
    try {
      await handleResend();
      replayAnimation();
      toast(
        formatMessage({
          defaultMessage: 'Link was resent',
          description: 'Monefit-ES: Link was resent',
        }),
        { type: 'success' }
      );
    } catch (e) {
      handleError(e as AxiosError);
    }
  }, [handleResend, replayAnimation, handleError, formatMessage]);
  return (
    <div className="inline-flex w-full flex-col items-center justify-between gap-5 rounded-3xl bg-white p-12 backdrop-blur-2xl sm:w-[610px]">
      <div className="inline-flex flex-col items-center justify-center gap-3.5 pt-2">
        <div className="size-[165px]">
          <Lottie
            key={animationKey}
            animationData={envelopeAnimation}
            loop={false}
            autoPlay={false}
          />
        </div>
        <div className="font-secondary text-2xl font-semibold leading-tight text-stone-950">
          <FormattedMessage
            defaultMessage="Check your email"
            description="Monefit-ES : Check your email"
          />
        </div>
        <div className="self-stretch text-center">
          <span className="text-lg font-medium leading-normal  text-black">
            <FormattedMessage
              defaultMessage="We sent magic link to"
              description="Monefit-ES : We sent magic link to"
            />
            <br />
          </span>
          <span className="text-lg font-semibold leading-normal  text-black">{email}</span>
        </div>
      </div>
      <div className="h-[40px] w-full text-center text-base text-black">
        {loading ? (
          <Lottie animationData={dots} style={{ height: '40px' }} />
        ) : (
          <Button
            type="button"
            onClick={async () => await resendEmail()}
            color="underlined-black"
            text={formatMessage({
              defaultMessage: 'Resend email',
              description: 'Monefit-ES Resend email',
            })}
          />
        )}
      </div>
    </div>
  );
};

export default CheckEmailCard;
