import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import AccordionGroup from '@common/components/accordion/AccordionGroup';

const WaitingListFAQ = () => {
  const accordionItems = useMemo(
    () => [
      {
        title: (
          <FormattedMessage
            defaultMessage="How do you provide a 0% interest rate?"
            description="Monefit-ES 'How do you provide a 0% interest rate?'"
          />
        ),
        content: (
          <FormattedMessage
            defaultMessage="Get a credit limit of up to €10,000 with Monefit Credit. Pay back on time and enjoy a 0%
        interest rate, making your credit truly interest-free."
            description="Monefit-ES Get a credit limit of up to €10,000 with Monefit Credit. Pay back on time and enjoy a 0%
        interest rate, making your credit truly interest-free."
          />
        ),
        defaultOpen: true,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="How quickly can I receive the money?"
            description="Monefit-ES 'How quickly can I receive the money?'"
          />
        ),

        content: (
          <FormattedMessage
            defaultMessage="Applying for Monefit Credit is simple and fast. Just submit your application and provide the necessary documents for a background check. Once the process is complete, you can immediately access your credit limit of up to €10,000. Enjoy fast and efficient service with Monefit Credit!"
            description="Monefit-ES: Applying for Monefit Credit is simple and fast. Just submit your application and provide the necessary documents for a background check. Once the process is complete, you can immediately access your credit limit of up to €10,000. Enjoy fast and efficient service with Monefit Credit!"
          />
        ),
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="When can I start using Monefit Credit?"
            description="Monefit-ES When can I start using Monefit Credit?"
          />
        ),

        content: (
          <FormattedMessage
            defaultMessage="The wait is almost over! Our new product will be launched soon, and you can be among the first to access it. Join our waiting list now for early access to our services. Follow us on social media to stay updated and be the first to hear our news. Stay tuned! "
            description="Moenfit-ES: The wait is almost over! Our new product will be launched soon, and you can be among the first to access it. Join our waiting list now for early access to our services. Follow us on social media to stay updated and be the first to hear our news. Stay tuned! "
          />
        ),
      },
    ],
    []
  );

  return <AccordionGroup items={accordionItems} />;
};

export default WaitingListFAQ;
