import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useLocale } from '@common/hooks';
import { getIsLoggedIn } from '@common/utils';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import useAuth from '@monefit-es/hooks/useAuth';

// import PrivateRouteDebug from './PrivateRouteDebug';

interface PrivateRouteProps {
  children: ReactNode;
  hasMainLayout?: boolean;
}

const PrivateRoute = ({ children, hasMainLayout = true }: PrivateRouteProps) => {
  const { localeWithCountry } = useLocale();
  const isLoggedIn = !!getIsLoggedIn();
  useAuth();

  return (
    <>
      {isLoggedIn ? (
        <>{hasMainLayout ? <MainLayout>{children}</MainLayout> : children}</>
      ) : (
        <Navigate replace to={`/${localeWithCountry}/login`} />
      )}
    </>
  );
};

export default PrivateRoute;
