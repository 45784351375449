import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';

import IconDown from '@common/components/icons/IconDown';
import IconUp from '@common/components/icons/IconUp';
import { useLocale } from '@common/hooks';

interface LanguageSelectOption {
  label: string;
  value: string;
  locale: string;
}

const LanguageSelect = ({ loading, showLoader }: { loading: boolean; showLoader: boolean }) => {
  const navigate = useNavigate();
  const { locale, setLocale, localeWithCountry, allowedLocales } = useLocale();
  const location = useLocation();

  const options = allowedLocales?.map((x) => {
    const localeFromOption = x.split('-')?.[0];
    return {
      label: localeFromOption?.toLocaleUpperCase(),
      value: x,
      locale: localeFromOption,
    };
  }, []);

  const handleSelect = useCallback(
    (selectedOption: LanguageSelectOption | null) => {
      const { pathname: currentLocation } = location;
      if (localeWithCountry === selectedOption?.value) {
        return;
      }
      if (localeWithCountry && selectedOption?.value) {
        const newLocation = currentLocation.replace(localeWithCountry, selectedOption?.value);
        setLocale(selectedOption.locale);
        navigate(newLocation, { replace: true });
      }
    },
    [location, navigate, setLocale, localeWithCountry]
  );

  return (
    <>
      {!!options?.length && (
        <Select
          isDisabled={loading && showLoader}
          isSearchable={false}
          options={options}
          value={options?.find((x) => x.locale === locale)}
          onChange={handleSelect}
          menuPlacement="bottom"
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: (props) =>
              props.selectProps.menuIsOpen ? (
                <IconUp height="18" width="18" strokeWidth={'2.5'} />
              ) : (
                <IconDown height="18" width="18" strokeWidth={'2.5'} />
              ),
          }}
          styles={{
            control: (base, props): any => ({
              ...base,
              borderColor: '#d1d5db',
              boxShadow: 'none',
              borderRadius: '0.75rem', // rounded-xl
              '&:hover': {
                borderColor: '#d1d5db', // gray-300
              },
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }),
            option: (base, state): any => ({
              ...base,
              cursor: 'pointer',
              color: 'rgba(0, 0, 0, 0.48)',
              backgroundColor: 'transparent',
              fontWeight: state.isFocused ? 'bold' : 'normal',
              '&:active': {
                backgroundColor: 'white',
              },
              '&:hover': {
                backgroundColor: '#F3F4F6', // gray-100
                borderRadius: '0.125rem', // rounded-sm,
              },
            }),
            input: (base) => ({
              ...base,
              fontWeight: 'bold',
            }),
            menu: (base): any => ({
              ...base,
              borderRadius: '0.75rem', // rounded-xl,
              width: '150px',
              border: '1px solid #d1d5db', // border-gray-300
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
              // left: '-5em',
            }),
            valueContainer: (base): any => ({
              ...base,
              display: 'flex',

              fontWeight: '700',
              padding: 0,
              flexWrap: 'no-wrap',
            }),
          }}
          classNames={{
            menu: () => 'left-[-60px] sm:left-[0px]',
          }}
        />
      )}
    </>
  );
};

export default LanguageSelect;
