import { LocalStorageKeys } from '@common/constants';
import { FlowType } from '@common/services/application';

import { ApiError } from './errors';
import { isUUID } from './uuid';

export const ALLOWED_FLOW_TYPES = [FlowType.APPLICATION, FlowType.DRAWDOWN];

export function isArrayOfStringErrors(arr: any[]): arr is string[] {
  return Array.isArray(arr) && arr.every((item) => typeof item === 'string');
}

export function getFlowId() {
  const urlPathComponents = window.location.pathname.split('/'); // e.g. ['', et-ee, application, ...rest]
  const possibleFlowTypeFromLocation = urlPathComponents?.[2];
  const countryCodeFromUrl = urlPathComponents?.[1]?.slice(-2).toUpperCase();

  if (isUUID(possibleFlowTypeFromLocation)) {
    return possibleFlowTypeFromLocation;
  }

  const storedFlowType = sessionStorage.getItem(LocalStorageKeys.FLOW_ID_OR_TYPE) as FlowType;
  const flowTypeToCheck = storedFlowType ?? possibleFlowTypeFromLocation ?? null;
  const isAllowedFlowType =
    flowTypeToCheck && ALLOWED_FLOW_TYPES.indexOf(flowTypeToCheck as FlowType) !== -1;

  if (!isAllowedFlowType) {
    throw new ApiError('Undefined flow type');
  }

  const applicationFlowId = process.env?.[`REACT_APP_${countryCodeFromUrl}_APPLICATION_FLOW_ID`];
  const drawdownFlowId = process.env?.[`REACT_APP_${countryCodeFromUrl}_DRAWDOWN_FLOW_ID`];

  switch (flowTypeToCheck) {
    case FlowType.APPLICATION:
      return applicationFlowId;
    case FlowType.DRAWDOWN:
      return drawdownFlowId;
    default:
      return applicationFlowId;
  }
}
