import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';

import { LocalStorageKeys } from '@common/constants';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions, FlowDefinition } from '@common/services/application';
import { delay, getCoreServiceBaseUrls } from '@common/utils';
import { getFlowId } from '@common/utils/helpers';
import { store } from '@monefit-ee/redux';
import axios from 'axios';

import { useApplicationFlowInstance } from './useApplicationFlowInstance';
import { Country } from './useCountry';

const FALLBACK_LOCALE = 'en';
const FALLBACK_LOCALES_EE = ['en-ee'];
const FALLBACK_LOCALES_ES = ['en-es'];
export const FALLBACK_LOCALE_EE = 'en-ee';
export const FALLBACK_LOCALE_ES = 'en-es';
const ALLOWED_LOCALES_ES = ['en-es', 'es-es'];

export enum Locales {
  EN = 'en',
  ET = 'et',
  RU = 'ru',
}

interface LocaleContextType {
  locale: string;
  setLocale: (locale: string) => void;
  allowedLocales: string[] | null;
  localeWithCountry: string;
  country: string;
}

interface LocaleProviderProps {
  children: ReactNode;
  country: string;
}

const LocaleContext = createContext<LocaleContextType | undefined>(undefined);

export const useLocale = () => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }
  return context;
};

export const LocaleProvider = ({ children, country }: LocaleProviderProps) => {
  const [instanceId] = useApplicationFlowInstance();
  const allowedLocalesRaw = sessionStorage.getItem(`${LocalStorageKeys.LOCALES}_${country}`);
  const localeFromUrl = window.location.pathname.split('/').slice(1)[0];
  const { applicationBaseUrl } = getCoreServiceBaseUrls();

  const [allowedLocales, setAllowedLocales] = useState<string[] | null>(() =>
    allowedLocalesRaw ? JSON.parse(allowedLocalesRaw) : null
  );

  const [locale, setLocaleState] = useState<string>(
    () => sessionStorage.getItem(`${LocalStorageKeys.LOCALE}_${country}`) || FALLBACK_LOCALE
  );

  const setLocale = useCallback(
    async (newLocale: string) => {
      const storedLocale = sessionStorage.getItem(`${LocalStorageKeys.LOCALE}_${country}`);
      const storedInstaceId = sessionStorage.getItem(LocalStorageKeys.APPLICATION_FLOW_INSTANCE_ID);
      if (storedLocale === newLocale) {
        return;
      }
      // TODO: Handle ES lang switch (when BE endpoint implemented)
      if (!window.location.pathname.includes('/start') && country === Country.EE) {
        store.dispatch(
          performApplicationFlowAction({
            action: FlowActions.SWITCH_LOCALE,
            instanceId: storedInstaceId ?? instanceId,
            data: { locale: `${newLocale}-${country}` },
          })
        );
      }

      setLocaleState(newLocale);
      sessionStorage.setItem(`${LocalStorageKeys.LOCALE}_${country}`, newLocale);
    },
    [instanceId, country]
  );

  const handleAllowedLocalesFallback = useCallback(
    (locales: string[]) => {
      setAllowedLocales(locales);
      sessionStorage.setItem(`${LocalStorageKeys.LOCALES}_${country}`, JSON.stringify(locales));
    },
    [country]
  );

  const loadLocalesEE = useCallback(async () => {
    if (!allowedLocales) {
      const baseUrl = applicationBaseUrl ?? null;
      if (!baseUrl) {
        handleAllowedLocalesFallback(FALLBACK_LOCALES_EE);
        return;
      }
      try {
        const flowId = getFlowId();
        const { data } = await axios.get<FlowDefinition>(baseUrl + '/flow-definition/' + flowId);
        setAllowedLocales(data.locales);
        sessionStorage.setItem(
          `${LocalStorageKeys.LOCALES}_${country}`,
          JSON.stringify(data.locales)
        );
      } catch (_) {
        handleAllowedLocalesFallback(FALLBACK_LOCALES_EE);
      }
    }
  }, [allowedLocales, handleAllowedLocalesFallback, country, applicationBaseUrl]);

  const loadLocalesES = useCallback(async () => {
    // TODO: hotfix, needs further investigation why sometimes on itinial load locale_es & locales_es are not stored to sessionStorage
    await delay(500);
    if (allowedLocales) {
      return;
    }

    try {
      setAllowedLocales(ALLOWED_LOCALES_ES);
      sessionStorage.setItem(
        `${LocalStorageKeys.LOCALES}_${country}`,
        JSON.stringify(ALLOWED_LOCALES_ES)
      );
    } catch {
      handleAllowedLocalesFallback(FALLBACK_LOCALES_ES);
    }
  }, [allowedLocales, country, handleAllowedLocalesFallback]);

  const loadLocales = useCallback(async () => {
    switch (country) {
      case Country.EE:
        await loadLocalesEE();
        break;
      case Country.ES:
        await loadLocalesES();
        break;
    }
  }, [country, loadLocalesEE, loadLocalesES]);

  useEffect(() => {
    loadLocales();
  }, []);

  useEffect(() => {
    if (!allowedLocales) {
      return;
    }
    const isAllowedLocale = allowedLocales?.indexOf(localeFromUrl) !== -1;
    // Store new locale in case of url was changed
    if (localeFromUrl && isAllowedLocale) {
      const newLocale = localeFromUrl.split('-')[0];
      setLocale(newLocale);
    } else if (localeFromUrl && !isAllowedLocale) {
      // Redirect back to valid url with allowed locale in case of wrong locale entered in url
      const location = window.location.pathname;
      const storedLocale = sessionStorage.getItem(`${LocalStorageKeys.LOCALE}_${country}`);
      const newLocation = location.replace(
        localeFromUrl,
        `${storedLocale ?? FALLBACK_LOCALE}-${country}`
      );
      window.location.replace(newLocation);
    }
  }, [localeFromUrl, allowedLocales, country, setLocale]);

  return (
    <LocaleContext.Provider
      value={{
        locale,
        setLocale,
        allowedLocales,
        localeWithCountry: `${locale}-${country}`,
        country,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};
