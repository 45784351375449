import { FC, JSX, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Form from '@common/components/form/Form';
import TextInput from '@common/components/form/inputs/textInput/TextInput';
import * as yup from 'yup';

export interface FormValues {
  amount: number;
}

interface ApplicationFlowStartFormProps {
  onSubmit: (values: FormValues) => void;
}

const ApplicationFlowStartForm: FC<ApplicationFlowStartFormProps> = ({ onSubmit }): JSX.Element => {
  const { formatMessage } = useIntl();

  const schema: yup.ObjectSchema<FormValues> = useMemo(
    () =>
      yup.object({
        amount: yup.number().required(
          formatMessage({
            defaultMessage: 'The field is mandatory',
            description: 'Start: "The field is mandatory" (amount field)',
          })
        ),
      }),
    [formatMessage]
  );

  return (
    <Form gapClass="gap-12" schema={schema} onSubmit={onSubmit}>
      <TextInput type="number" name="amount" label="Amount" step="1" defaultValue={1000} />
    </Form>
  );
};

export default ApplicationFlowStartForm;
