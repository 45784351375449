import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import { LINKS } from '@common/constants';
import waiting_list_2_img from '@src/assets/img/monefit-es/waiting_list_2.png';

const WaitingListSmartSaver = () => {
  const { formatMessage } = useIntl();
  return (
    <div className="flex items-end justify-between gap-2 rounded-3xl bg-[#CAD8CB] p-12 pb-0">
      <div className="pb-0 sm:pb-12">
        <div className="flex flex-col justify-center gap-4">
          <div className="font-secondary text-2xl font-semibold text-black sm:text-4xl sm:leading-10">
            <FormattedMessage
              defaultMessage={'<p>Explore Monefit {br} SmartSaver</p>'}
              description="Monefit-ES Explore Monefit {br} SmartSaver"
              values={{
                p: (...chunks) => <p style={{ letterSpacing: '0em' }}>{chunks}</p>,
                br: <br />,
              }}
            />
          </div>
          <div className="flex w-full flex-col gap-3 sm:max-w-[380px]">
            <div className="text-lg font-normal leading-snug text-black">
              <FormattedMessage
                defaultMessage="Join a global community from over 40 countries and enjoy returns between 7.25%
      and 9.96% APY"
                description="Monefit-ES: Join a global community from over 40 countries and enjoy returns between 7.25%
      and 9.96% APY"
              />
            </div>
            <div>
              <a href={LINKS.ES_SMARTSAVER} target="_blank" rel="noopener noreferrer">
                <Button
                  extraClassNames="font-bold font-secondary text-lg"
                  roundedFull={false}
                  text={formatMessage({
                    defaultMessage: 'Open SmartSaver',
                    description: 'Monefit-ES: Open SmartSaver',
                  })}
                  fullWidth
                />
              </a>
            </div>
          </div>
          <div className="flex w-full justify-center">
            <div
              className="flex h-[210px] w-[260px] sm:hidden"
              style={{
                backgroundImage: `url(${waiting_list_2_img})`,
                backgroundPosition: 'bottom',
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
              }}
            ></div>
          </div>
        </div>
      </div>
      <div
        className="hidden h-[240px] w-[280px] sm:flex lg:h-[300px] lg:w-[370px]"
        style={{
          backgroundImage: `url(${waiting_list_2_img})`,
          backgroundPosition: 'bottom',
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>
      <div></div>
    </div>
  );
};

export default WaitingListSmartSaver;
