import { useCallback } from 'react';

import Button from '@common/components/button/Button';
import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import { FALLBACK_LOCALE_EE, useApplicationFlowInstance, useLocale } from '@common/hooks';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { ApiError } from '@common/utils';
import { useAppDispatch } from '@monefit-ee/hooks';

const ApplicationFlowDrawdownStartPage = () => {
  const dispatch = useAppDispatch();
  const [, setInstanceId] = useApplicationFlowInstance();
  const { localeWithCountry: localeParamWithCountry } = useLocale();

  // Generated token from .env file
  const { REACT_APP_DRAWDOWN_FLOW_USER_TOKEN } = process.env;

  const handleContinue = useCallback(() => {
    dispatch(
      performApplicationFlowAction({
        action: FlowActions.SUBMIT,
        data: {
          userToken: REACT_APP_DRAWDOWN_FLOW_USER_TOKEN,
          locale: localeParamWithCountry ?? FALLBACK_LOCALE_EE,
        },
      })
    )
      .unwrap()
      .then((res) => {
        if (res?.id) {
          setInstanceId(res.id);
          window.location.reload();
        }
      })
      .catch((e) => {
        throw new ApiError(String(e));
      });
  }, [dispatch, setInstanceId, localeParamWithCountry, REACT_APP_DRAWDOWN_FLOW_USER_TOKEN]);
  return (
    <SignupLayout>
      <div>
        <Button text="Continue" onClick={handleContinue} />
      </div>
    </SignupLayout>
  );
};

export default ApplicationFlowDrawdownStartPage;
