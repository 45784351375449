import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import Button, { ButtonSpacing } from '@common/components/button/Button';
import { OtpFormType } from '@monefit-ee/components/forms/OtpCodeForm';
import { saveOtpRemaingTimeToStorage } from '@monefit-ee/components/forms/OtpCodeForm.helpers';

interface ITimerProps {
  timeRemaining: number;
  setTimeRemaining: React.Dispatch<React.SetStateAction<number>>;
  handleResend: () => void;
  limit: number;
  otpFormType: OtpFormType;
}

const Timer = ({
  timeRemaining,
  setTimeRemaining,
  handleResend,
  limit,
  otpFormType,
}: ITimerProps) => {
  const [timerActive, setTimerActive] = useState(true);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (timerActive) {
      intervalRef.current = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime === 0) {
            clearInterval(intervalRef.current!);
            setTimerActive(false);
            saveOtpRemaingTimeToStorage(0, otpFormType);
            return 0;
          }
          saveOtpRemaingTimeToStorage(prevTime - 1, otpFormType);
          return prevTime - 1;
        });
      }, 1000);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [timerActive, setTimeRemaining, otpFormType]);

  const timerDisplayValue = useMemo(
    () => `${Math.floor(timeRemaining / 60)}:${(timeRemaining % 60).toString().padStart(2, '0')}`,
    [timeRemaining]
  );

  const onResend = () => {
    handleResend();
    setTimeRemaining(limit);
    saveOtpRemaingTimeToStorage(limit, otpFormType);
  };

  return (
    <div className="w-full">
      {timeRemaining ? (
        <p className="text-center">
          {formatMessage({
            defaultMessage: 'Please wait',
            description: 'OtpCode: Timer -> Please wait',
          })}{' '}
          <span className="font-bold">{timerDisplayValue}</span>{' '}
          {formatMessage({
            defaultMessage: 'minutes before requesting a new code',
            description: 'OtpCode: Timer -> minutes before requesting a new code',
          })}
        </p>
      ) : (
        <div className="flex w-full justify-center">
          <Button
            spacing={ButtonSpacing.NONE}
            color="underlined-black"
            text={formatMessage({
              defaultMessage: 'Resend',
              description: 'OtpCode: Resend button text',
            })}
            disabled={!!timeRemaining}
            onClick={onResend}
          />
        </div>
      )}
    </div>
  );
};

export default Timer;
