import Form from '@common/components/form/Form';
import { TrackEvent } from '@common/utils/amplitude/events';

const IdCardForm = () => {
  return (
    <Form submitTrackEventName={TrackEvent.SIGNED_PAGE_SUBMIT} gapClass="gap-12">
      <div className="text-center">ID Card form</div>
    </Form>
  );
};

export default IdCardForm;
