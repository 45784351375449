import { useEffect, useMemo, useState } from 'react';

interface ProgressBarProps {
  progress?: number;
}

const ProgressBar = ({ progress = 0 }: ProgressBarProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoaded(true);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  const width = useMemo(() => {
    if (progress > 1) {
      return 1;
    }
    if (progress < 0) {
      return 0;
    }
    return progress;
  }, [progress]);

  const widthToStartTransition = useMemo(() => {
    if (width === 0 || width === 1) {
      return width;
    }
    return width - 0.05;
  }, [width]);

  return (
    <div className="w-full overflow-hidden rounded-full bg-white p-1 shadow-sm">
      <div
        className="h-3 rounded-full bg-blue-brand"
        style={{
          width: `${isLoaded ? width * 100 : widthToStartTransition * 100}%`,
          transition: 'width 0.5s ease-in-out',
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
