import { useCallback } from 'react';

import { LocalStorageKeys } from '@common/constants';

import { useApplicationFlowInstance } from './useApplicationFlowInstance';

interface UserSessionStorageData {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  personalCode?: string;
}

const useUserSessionData = () => {
  const [instanceId] = useApplicationFlowInstance();

  const getFromStorage = useCallback(() => {
    const rawData = sessionStorage.getItem(`${LocalStorageKeys.USER_PREFILLED_DATA}_${instanceId}`);
    return rawData ? (JSON.parse(rawData) as UserSessionStorageData) : null;
  }, [instanceId]);

  const saveToStorage = useCallback(
    (data: UserSessionStorageData) => {
      const existingData = getFromStorage();
      const dataToSave = existingData ? { ...existingData, ...data } : data;
      sessionStorage.setItem(
        `${LocalStorageKeys.USER_PREFILLED_DATA}_${instanceId}`,
        JSON.stringify(dataToSave)
      );
    },
    [getFromStorage, instanceId]
  );

  return {
    getFromStorage,
    saveToStorage,
  };
};

export default useUserSessionData;
