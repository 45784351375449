const CopyIcon2 = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="10.3996"
        y="10.4511"
        width="9.6"
        height="11.2"
        rx="0.8"
        stroke="black"
        strokeWidth="1.6"
      />
      <path
        d="M11.2012 8.05127H22.4012V20.8513"
        stroke="black"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyIcon2;
