import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

import { useLocale } from '@common/hooks';
import { useCookieConsents } from '@common/hooks/useCookieConsents';
import { getUserDisplayName } from '@common/utils';

const Intercom = () => {
  const { consents, loaded: constentsLoaded } = useCookieConsents();

  const { boot, update } = useIntercom();

  const userName = getUserDisplayName();
  const { locale } = useLocale();

  useEffect(() => {
    if (!constentsLoaded || (constentsLoaded && !consents.necessary)) {
      return;
    }
    boot({ languageOverride: locale, name: userName ?? '' });
  }, [locale, boot, userName, consents.necessary, constentsLoaded]);

  useEffect(() => {
    if (!constentsLoaded || (constentsLoaded && !consents.necessary)) {
      return;
    }
    update({ languageOverride: locale, name: userName ?? '' });
  }, [locale, update, userName, consents.necessary, constentsLoaded]);

  return <></>;
};

export default Intercom;
