import { ReactNode } from 'react';

import { useAppSelector } from '@monefit-ee/hooks';

import Footer from '../footer/Footer';
import Header from '../header/Header';
import MainLoader from '../loader/MainLoader';

interface SignupLayoutProps {
  children: ReactNode;
  hideProgressBar?: boolean;
}

const SignupLayout = ({ children, hideProgressBar }: SignupLayoutProps) => {
  const { loading, meta } = useAppSelector((state) => state.application.flowInstance);

  return (
    <div className="flex h-screen flex-col justify-between gap-6 bg-stone-50 p-2 md:p-12 ">
      <Header hideProgressBar={hideProgressBar} />
      <div className="flex w-full items-center justify-center">
        {loading ? meta?.showLoader ? <MainLoader /> : children : children}
      </div>
      <Footer />
    </div>
  );
};

export default SignupLayout;
