import { IconProps } from '../types';

const YoutubeIcon = ({ isBlackAndWhite }: IconProps) => {
  return (
    <svg width="63" height="44" viewBox="0 0 63 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1246_1194)">
        <g clipPath="url(#clip1_1246_1194)">
          <path
            d="M61.6829 6.87113C60.957 4.16511 58.8253 2.03857 56.113 1.31421C51.202 3.93391e-07 31.5 0 31.5 0C31.5 0 11.7983 3.93391e-07 6.88707 1.31421C4.17478 2.03857 2.04331 4.16511 1.31726 6.87113C3.94304e-07 11.7709 0 22 0 22C0 22 3.94304e-07 32.2291 1.31726 37.129C2.04331 39.835 4.17478 41.9615 6.88707 42.6857C11.7983 44 31.5 44 31.5 44C31.5 44 51.202 44 56.113 42.6857C58.8253 41.9615 60.957 39.835 61.6829 37.129C63.0002 32.2291 63.0002 22 63.0002 22C63.0002 22 62.9949 11.7709 61.6829 6.87113Z"
            fill={isBlackAndWhite ? 'black' : '#FF0000'}
            fillOpacity={isBlackAndWhite ? '48%' : '100%'}
          />
          <path d="M25.1934 31.4279L41.5606 22.0009L25.1934 12.5737V31.4279Z" fill="white" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1246_1194">
          <rect width="63" height="44" fill="white" />
        </clipPath>
        <clipPath id="clip1_1246_1194">
          <rect width="63" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default YoutubeIcon;
