import { useCallback } from 'react';

import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import useTracker from '@common/hooks/useTracker';
import {
  IdentificationProvider,
  IdentificationProvidersResponse,
  IdentificationSubmitResponse,
  SubStep,
} from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';
import { useAppSelector } from '@monefit-ee/hooks';
import useIdentification from '@monefit-ee/hooks/usePendingIdentification';

import PinView from './views/PinView';
import Providers from './views/ProvidersView';

const IdentificationPage = () => {
  useTracker({ initEventName: TrackEvent.SIGNUP_PAGE_INIT });
  const {
    data,
    meta: { currentSubStepId },
  } = useAppSelector((state) => state.application.flowInstance);

  const { pendingIdentification } = useIdentification();

  const renderView = useCallback(() => {
    if (currentSubStepId) {
      switch (currentSubStepId) {
        case SubStep.SMART_ID_PENDING:
        case SubStep.MOBILE_ID_PENDING:
        case SubStep.ID_CARD_PENDING:
          return <PinView data={data?.responseData as IdentificationSubmitResponse} />;
        default:
          return <PinView data={data?.responseData as IdentificationSubmitResponse} />;
      }
    } else if (pendingIdentification?.provider) {
      switch (pendingIdentification.provider) {
        case IdentificationProvider.SMART_ID:
        case IdentificationProvider.MOBILE_ID:
        case IdentificationProvider.ID_CARD:
          return <PinView data={data?.responseData as IdentificationSubmitResponse} />;
        default:
          return <PinView data={data?.responseData as IdentificationSubmitResponse} />;
      }
    } else {
      return <Providers data={data?.responseData as IdentificationProvidersResponse} />;
    }
  }, [data?.responseData, currentSubStepId, pendingIdentification]);

  return <SignupLayout>{renderView()}</SignupLayout>;
};

export default IdentificationPage;
