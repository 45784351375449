import { IdentificationSubmitResponse } from '@common/services/application';
import PinCard from '@monefit-ee/pages/identification-page/PinCard';

const PinView = ({
  data,
  headerText,
  subHeaderText,
}: {
  data: IdentificationSubmitResponse;
  headerText?: string;
  subHeaderText?: string;
}) => {
  return <PinCard {...{ data, subHeaderText, headerText }} />;
};

export default PinView;
