import { LocalStorageKeys } from '@common/constants';

import { OtpFormType } from './OtpCodeForm';

export const saveOtpRemaingTimeToStorage = (value: number, type: OtpFormType) => {
  sessionStorage.setItem(`${LocalStorageKeys.TIMER_REMAINING}_${type}`, value.toString());
};

export const getOtpRemaingTimeFromStorage = (type: OtpFormType) => {
  const remainingTimeString = sessionStorage.getItem(`${LocalStorageKeys.TIMER_REMAINING}_${type}`);
  return remainingTimeString ? Number(remainingTimeString) : undefined;
};
