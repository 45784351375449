import React, { FC, JSX, useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { LocalStorageKeys, MONEFIT_PUBLIC_SITE_URL } from '@common/constants';
import { useApplicationFlowInstance, useApplicationRoutingPageMap } from '@common/hooks';
import { FlowSteps, FlowType } from '@common/services/application';
import { ALLOWED_FLOW_TYPES } from '@common/utils/helpers';
import { isUUID } from '@common/utils/uuid';
import { useAppSelector } from '@monefit-ee/hooks';
import { kebabCase } from 'lodash';

export interface ApplicationRouteHandlerProps {
  localePath: string;
  children?: JSX.Element;
  flowSteps: FlowSteps[];
}

/**
 * Component for handling application flow related route corrections
 **/
const ApplicationRouteHandler: FC<ApplicationRouteHandlerProps> = ({
  localePath,
  children,
  flowSteps = [],
}) => {
  const location = useLocation();
  const { instanceId, flowIdOrType: flowIdOrTypeParam } = useParams();
  const { flowInstance } = useAppSelector((state) => state.application);
  const [storedInstanceId, setStoredInstanceId] = useApplicationFlowInstance();
  const [pageMap] = useApplicationRoutingPageMap(flowSteps);
  const [redirectPath, setRedirectPath] = useState<string>();

  const possibleFlowIdOrTypeFromLocation = location.pathname.split('/')?.[2]; // e.g. ['', et-ee, application, ...rest]
  const flowIdOrType = flowIdOrTypeParam ?? possibleFlowIdOrTypeFromLocation ?? null;
  const isAllowedFlowType = isUUID(flowIdOrType)
    ? true
    : flowIdOrType && ALLOWED_FLOW_TYPES.indexOf(flowIdOrType as FlowType) !== -1;

  useEffect(() => {
    if (
      instanceId &&
      instanceId !== storedInstanceId &&
      (location.pathname.includes(`/start`) || location.pathname.includes(`/start`))
    ) {
      setStoredInstanceId(instanceId);
    }
  }, [instanceId, storedInstanceId, setStoredInstanceId, location.pathname]);

  // Store flow type on flow start
  useEffect(() => {
    if (
      flowIdOrType &&
      isAllowedFlowType &&
      (location.pathname.includes(`/start`) || location.pathname.includes(`/start`))
    ) {
      sessionStorage.setItem(LocalStorageKeys.FLOW_ID_OR_TYPE, flowIdOrType);
    }
  }, [location.pathname, isAllowedFlowType, flowIdOrType]);

  // Setup redirect to page referred by application service if needed
  useEffect(() => {
    if (!isAllowedFlowType) {
      window.location.replace(MONEFIT_PUBLIC_SITE_URL);
      return;
    }

    const basePath = `/${localePath}/${flowIdOrType}`;
    const currentStepIdPath = `${basePath}/${kebabCase(flowInstance.data?.currentStepId)}`;

    if (currentStepIdPath !== location.pathname) {
      pageMap.forEach((page) => {
        if (currentStepIdPath === `${basePath}/${page.path}`) {
          setRedirectPath(`${basePath}/${page.path}`);
        }
      });
    }
  }, [
    flowIdOrType,
    localePath,
    isAllowedFlowType,
    pageMap,
    location.pathname,
    flowInstance.data?.currentStepId,
  ]);

  if (redirectPath && redirectPath !== location.pathname) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ?? null;
};

export default ApplicationRouteHandler;
