import { FC, JSX } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';

import Intercom from '@common/components/Intercom';
import { useLocale } from '@common/hooks';
import useMessages from '@common/hooks/useMessages';
import { store } from '@monefit-es/redux';
import Router from '@src/monefit-es/router/Router';

const App: FC = (): JSX.Element | null => {
  const { locale } = useLocale();
  const { messages, messagesLoading } = useMessages();

  if (!messages || messagesLoading) {
    return null;
  }

  const { REACT_APP_INTERCOM_APP_ID } = process.env;

  return (
    <IntlProvider locale={locale} messages={messages}>
      <IntercomProvider appId={REACT_APP_INTERCOM_APP_ID}>
        <Intercom />
        <Provider store={store}>
          <div data-testid="monefit-es-app">
            <Router />
          </div>
        </Provider>
      </IntercomProvider>
    </IntlProvider>
  );
};

export default App;
