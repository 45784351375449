import { useEffect } from 'react';

import MainLoader from '@common/components/loader/MainLoader';
import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import { useApplicationFlowInstance } from '@common/hooks';
import useTracker from '@common/hooks/useTracker';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions, RedirectResponse } from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';
import { useAppDispatch, useAppSelector } from '@monefit-ee/hooks';

import ErrorBoundaryPage from '../error-boundary-page/ErrorBoundaryPage';

const RedirectPage = () => {
  const { error, loading } = useAppSelector((st) => st.application.flowInstance);
  const [instanceId] = useApplicationFlowInstance();
  const dispatch = useAppDispatch();
  useTracker({
    initEventName: TrackEvent.REDIRECT_PAGE_INIT,
    errorTrackEventName: TrackEvent.REDIRECT_PAGE_ERROR,
  });

  useEffect(() => {
    dispatch(performApplicationFlowAction({ action: FlowActions.FETCH, instanceId }))
      .unwrap()
      .then((res) => {
        const { redirectUrl } = res.responseData as RedirectResponse;
        window.location.replace(redirectUrl);
      });
  }, [dispatch, instanceId]);

  if (error && !loading) {
    return <ErrorBoundaryPage />;
  }

  return (
    <SignupLayout>
      <MainLoader />
    </SignupLayout>
  );
};

export default RedirectPage;
