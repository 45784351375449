import './spinner-loader.css';

interface Props {
  sizeInPixels?: number;
  border?: number;
  borderTopColor?: string;
  borderColor?: string;
}

const SpinnerLoader = ({
  sizeInPixels = 20,
  border = 2,
  borderTopColor = 'black',
  borderColor = '#f3f3f3',
}: Props) => {
  return (
    <div
      style={{
        width: `${sizeInPixels}px`,
        height: `${sizeInPixels}px`,
        border: `${border}px solid ${borderColor}`,
        borderTop: `${border}px solid ${borderTopColor}`,
      }}
      className="spinner-loader"
    ></div>
  );
};

export default SpinnerLoader;
