import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

import RootApp from './RootApp';
import reportWebVitals from './reportWebVitals';

import './index.css';

const { REACT_APP_GTM_ID = null } = process.env;

if (REACT_APP_GTM_ID) {
  const tagManagerArgs: TagManagerArgs = {
    gtmId: REACT_APP_GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RootApp />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// temporarily disabled
// if (process.env.NODE_ENV === 'development') {
//   const { serviceWorker } = require('./__mocks__/browser');
//   serviceWorker.start();
// }
