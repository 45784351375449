import { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import Button, { ButtonSpacing } from '@common/components/button/Button';

interface ButtonGroupInputProps {
  name: string;
  label?: string;
  externalHandler?: () => void;
  options: {
    label: string;
    value: any;
  }[];
  isBoldLabel?: boolean;
}

const EXTRA_CLASS_NAMES = 'font-medium w-full';

const ButtonGroupInput = ({
  name,
  label,
  options,
  externalHandler,
  isBoldLabel = false,
}: ButtonGroupInputProps) => {
  useController({
    name,
    rules: {},
  });

  const { getValues, setValue, trigger } = useFormContext();

  const onClick = useCallback(
    (value: any) => {
      const currentValues = getValues(name) ?? [];
      const isSelected = currentValues.indexOf(value) !== -1;

      if (isSelected) {
        setValue(
          name,
          currentValues.filter((x: any) => x !== value)
        );
      } else {
        setValue(name, [...currentValues, value]);
      }
      if (externalHandler) {
        externalHandler();
      }
      trigger(name);
    },
    [getValues, name, setValue, trigger, externalHandler]
  );

  const getColor = useCallback(
    (value: any) => {
      const currentValues = getValues(name) ?? [];
      return currentValues.indexOf(value) !== -1 ? 'blue' : 'gray';
    },
    [getValues, name]
  );

  return (
    <div className="inline-flex w-full flex-col items-start justify-start gap-2.5">
      <div
        className={`text-sm font-medium leading-normal text-black ${
          isBoldLabel ? 'text-[15px] text-opacity-100' : 'text-opacity-50'
        }`}
      >
        {label}
      </div>
      <div className="flex h-20 w-full gap-2 sm:gap-4">
        {options.map((opt) => (
          <Button
            type="button"
            color={getColor(opt.value)}
            text={opt.label}
            key={opt.value}
            spacing={ButtonSpacing.BUTTON_GROUP}
            roundedFull={false}
            extraClassNames={EXTRA_CLASS_NAMES}
            onClick={() => onClick(opt.value)}
          />
        ))}
      </div>
    </div>
  );
};

export default ButtonGroupInput;
