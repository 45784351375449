import ReactCodeInput from 'react-verification-code-input';

import './otpCodeInput.css';

interface OtpCodeInputProps {
  otpCode?: string;
  handleCodeChange: (v: string) => void;
  codeLength?: number;
}

const OtpCodeInput = ({ otpCode, handleCodeChange, codeLength = 4 }: OtpCodeInputProps) => {
  return (
    <>
      <div className="otp-code flex w-full flex-col gap-3">
        <ReactCodeInput
          onComplete={(v) => handleCodeChange(v)}
          onChange={(v) => handleCodeChange(v)}
          fields={codeLength}
        />
      </div>
    </>
  );
};

export default OtpCodeInput;
