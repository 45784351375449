import { useMemo } from 'react';

export enum NotificationType {
  Success = 'success',
  SuccessSecondary = 'succes-secondary',
  Error = 'error',
  Warning = 'warning',
  InfoMain = 'info-main',
  InfoSecondary = 'info-secondary',
}

interface NotificationProps {
  type: NotificationType;
  message: string;
  icon?: JSX.Element;
  spacing?: 'large' | 'normal';
  rounded?: 'full' | '2xl' | 'xl' | 'md';
  textAlign?: 'left' | 'center' | 'right';
}

const SPACING_CLASSES = {
  large: 'px-6 py-4',
  normal: 'px-8 py-3',
};

const ROUND_CLASSES = {
  full: 'rounded-full',
  '2xl': 'rounded-2xl',
  xl: 'rounded-xl',
  md: 'rounded-md',
};

const TEXT_ALIGN_CLASSES = {
  left: 'justy-start',
  center: 'justify-center',
  right: 'justify-end',
};

const Notification = ({
  type,
  message,
  icon,
  spacing = 'normal',
  rounded = 'full',
  textAlign = 'center',
}: NotificationProps) => {
  const spacingClass = SPACING_CLASSES[spacing];
  const roundClass = ROUND_CLASSES[rounded];
  const textAlignClass = TEXT_ALIGN_CLASSES[textAlign];

  const colorClass = useMemo(() => {
    switch (type) {
      case NotificationType.Error:
        return 'bg-error';
      case NotificationType.Success:
        return 'bg-success';
      case NotificationType.Warning:
        return 'bg-warning';
      case NotificationType.InfoMain:
        return 'bg-blue-brand-light';
      case NotificationType.InfoSecondary:
        return 'bg-white';
      case NotificationType.SuccessSecondary:
        return 'bg-green-brand';
      default:
        return 'bg-blue-brand-light';
    }
  }, [type]);

  return (
    <div
      className={`${colorClass} ${spacingClass} ${roundClass} ${textAlignClass} flex w-full items-center text-sm text-black`}
    >
      <div className="flex items-center gap-2 text-sm font-medium leading-snug">
        {icon && icon}
        <span>{message}</span>
      </div>
    </div>
  );
};

export default Notification;
