import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import LanguageSelect from '@common/components/LanguageSelect';
import Button from '@common/components/button/Button';
import LogoIcon from '@common/components/icons/LogoIcon';
import { useLocale } from '@common/hooks';
import { getIsLoggedIn, getUserDisplayName, handleLogout } from '@common/utils';

const Header = ({ userDisplayNameNode }: { userDisplayNameNode?: ReactNode }) => {
  const { formatMessage } = useIntl();
  const { localeWithCountry } = useLocale();
  const isLoggedIn = getIsLoggedIn();

  return (
    <div className="flex w-full flex-col items-center justify-center gap-3 p-2 lg:flex-row lg:justify-between lg:gap-0">
      <div className="flex items-center justify-start gap-5">
        <LogoIcon />
        {/* TODO: enable language switch */}
        {/* <LanguageSelect loading={false} showLoader /> */}
      </div>
      {isLoggedIn &&
        (userDisplayNameNode ?? (
          <div className="font-secondary text-2xl">
            <FormattedMessage
              defaultMessage="Hey, {userName}"
              values={{ userName: getUserDisplayName() }}
            />
          </div>
        ))}
      {isLoggedIn && (
        <div className="hidden lg:flex">
          <Button
            extraClassNames="font-bold font-secondary"
            color="dark-gray"
            onClick={() => handleLogout(localeWithCountry)}
            text={formatMessage({ defaultMessage: 'Log out', description: 'Monefit-ES: logout' })}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
