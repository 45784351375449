import LoanStatusCard, { LoanStatus } from '@common/components/loan-status-card/LoanStatusCard';
import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import useTracker from '@common/hooks/useTracker';
import { TrackEvent } from '@common/utils/amplitude/events';

const DeclinedPage = () => {
  useTracker({
    initEventName: TrackEvent.DECLINED_PAGE_INIT,
    errorTrackEventName: TrackEvent.DECLINED_PAGE_ERROR,
  });
  return (
    <SignupLayout>
      <LoanStatusCard status={LoanStatus.REJECTED} />
    </SignupLayout>
  );
};

export default DeclinedPage;
