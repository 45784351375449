import { useEffect, useState } from 'react';

export enum Environment {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

const useEnvironment = () => {
  const definedEnv = process.env.REACT_APP_ENVIRONMENT as Environment;
  const [environment, setEnvironment] = useState<Environment | null>(null);

  useEffect(() => {
    if (Object.values(Environment).includes(definedEnv)) {
      setEnvironment(definedEnv);
    }
  }, [definedEnv]);

  return { environment };
};

export default useEnvironment;
