import { useCallback } from 'react';

import { LocalStorageKeys } from '@common/constants';

export const useApplicationFlowInstance = (): [string | null, (id: string) => void] => {
  const instanceId = sessionStorage.getItem(LocalStorageKeys.APPLICATION_FLOW_INSTANCE_ID);

  const setInstanceId = useCallback<(id: string) => void>((id) => {
    sessionStorage.setItem(LocalStorageKeys.APPLICATION_FLOW_INSTANCE_ID, id);
  }, []);

  return [instanceId, setInstanceId];
};
