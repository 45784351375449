const FooterLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="81" height="17" viewBox="0 0 81 17" fill="none">
      <path
        d="M0.65394 16.3454C0.551312 16.2431 0.5 16.1183 0.5 15.9715V5.59207C0.5 5.44563 0.551312 5.32081 0.65394 5.21818C0.756243 5.11582 0.88107 5.06424 1.02783 5.06424H2.74311C2.88922 5.06424 3.01405 5.11582 3.117 5.21818C3.2193 5.32081 3.27094 5.44563 3.27094 5.59207V6.33957C4.03281 5.34268 5.09603 4.84424 6.45957 4.84424C8.08692 4.84424 9.22274 5.49662 9.86788 6.8014C10.2199 6.21534 10.718 5.74239 11.3635 5.38292C12.0084 5.024 12.7048 4.84424 13.4527 4.84424C14.6545 4.84424 15.637 5.25475 16.3994 6.07577C17.1616 6.89679 17.5427 8.09175 17.5427 9.66022V15.9715C17.5427 16.1183 17.495 16.2431 17.3999 16.3454C17.3045 16.448 17.1764 16.4994 17.0152 16.4994H15.2117C15.0653 16.4994 14.9404 16.448 14.8378 16.3454C14.7352 16.2431 14.6842 16.1183 14.6842 15.9715V9.83609C14.6842 8.91244 14.4897 8.24175 14.1014 7.82405C13.7128 7.40603 13.1961 7.19716 12.5509 7.19716C11.9793 7.19716 11.4916 7.40992 11.0886 7.83486C10.6853 8.26038 10.4837 8.92746 10.4837 9.83609V15.9715C10.4837 16.1183 10.4323 16.2431 10.33 16.3454C10.2271 16.448 10.1026 16.4994 9.95609 16.4994H8.15266C8.00617 16.4994 7.88134 16.448 7.77904 16.3454C7.67609 16.2431 7.6251 16.1183 7.6251 15.9715V9.83609C7.6251 8.91244 7.42346 8.24175 7.02014 7.82405C6.61681 7.40603 6.10756 7.19716 5.4918 7.19716C4.90547 7.19716 4.41064 7.40992 4.00764 7.83486C3.60399 8.26038 3.40268 8.92746 3.40268 9.83609V15.9715C3.40268 16.1183 3.35137 16.2431 3.24874 16.3454C3.14611 16.448 3.02128 16.4994 2.87512 16.4994H1.02783C0.88107 16.4994 0.756243 16.448 0.65394 16.3454Z"
        fill="black"
      />
      <path
        d="M27.1939 13.8055C27.6189 13.3143 27.8535 12.5926 27.8976 11.6393C27.9121 11.4931 27.9195 11.2071 27.9195 10.7816C27.9195 10.3567 27.9121 10.0707 27.8976 9.92397C27.8535 8.97121 27.6189 8.24925 27.1939 7.75799C26.7684 7.26705 26.1677 7.02129 25.3908 7.02129C24.6136 7.02129 24.0125 7.26705 23.5873 7.75799C23.1621 8.24925 22.9277 8.97121 22.8836 9.92397L22.8617 10.7816L22.8836 11.6393C22.9277 12.5926 23.1621 13.3143 23.5873 13.8055C24.0125 14.2968 24.6136 14.5422 25.3908 14.5422C26.1677 14.5422 26.7684 14.2968 27.1939 13.8055ZM21.4102 15.3888C20.4718 14.502 19.9662 13.2885 19.893 11.7494L19.8711 10.7816L19.893 9.81416C19.9662 8.28944 20.479 7.08011 21.4324 6.18558C22.3851 5.29164 23.7046 4.84424 25.3908 4.84424C27.0763 4.84424 28.3958 5.29164 29.3488 6.18558C30.3019 7.08011 30.8147 8.28944 30.8883 9.81416C30.9029 9.99003 30.9105 10.3126 30.9105 10.7816C30.9105 11.2509 30.9029 11.5732 30.8883 11.7494C30.8147 13.2885 30.3091 14.502 29.371 15.3888C28.4327 16.2761 27.106 16.7193 25.3908 16.7193C23.6755 16.7193 22.3482 16.2761 21.4102 15.3888Z"
        fill="black"
      />
      <path
        d="M33.5836 16.3454C33.481 16.2431 33.4297 16.1183 33.4297 15.9715V5.59207C33.4297 5.44563 33.481 5.32081 33.5836 5.21818C33.6859 5.11582 33.8108 5.06424 33.9575 5.06424H35.7607C35.9071 5.06424 36.0317 5.11582 36.1346 5.21818C36.2369 5.32081 36.2886 5.44563 36.2886 5.59207V6.49351C37.1534 5.394 38.3774 4.84424 39.9609 4.84424C41.3095 4.84424 42.3799 5.28775 43.1715 6.17483C43.9631 7.06185 44.3592 8.26756 44.3592 9.79228V15.9715C44.3592 16.1183 44.3112 16.2431 44.216 16.3454C44.1207 16.448 43.9925 16.4994 43.8314 16.4994H41.8961C41.7494 16.4994 41.6245 16.448 41.5222 16.3454C41.4196 16.2431 41.3683 16.1183 41.3683 15.9715V9.92403C41.3683 9.0592 41.1594 8.3885 40.7417 7.91199C40.3237 7.43574 39.7191 7.19716 38.9274 7.19716C38.1502 7.19716 37.5381 7.43904 37.091 7.92306C36.6438 8.40681 36.4206 9.07416 36.4206 9.92403V15.9715C36.4206 16.1183 36.3726 16.2431 36.2774 16.3454C36.182 16.448 36.0536 16.4994 35.8924 16.4994H33.9575C33.8108 16.4994 33.6859 16.448 33.5836 16.3454Z"
        fill="black"
      />
      <path
        d="M54.3765 9.79228V9.72622C54.3765 8.8614 54.1601 8.16494 53.7277 7.63711C53.2953 7.10955 52.6978 6.84547 51.9357 6.84547C51.1731 6.84547 50.5793 7.10955 50.1544 7.63711C49.7289 8.16494 49.5167 8.8614 49.5167 9.72622V9.79228H54.3765ZM48.0541 15.3012C47.0938 14.3556 46.5843 13.025 46.5258 11.3098L46.5039 10.7597L46.5258 10.21C46.599 8.53883 47.1158 7.22687 48.076 6.27384C49.0363 5.32075 50.3227 4.84424 51.9357 4.84424C53.6653 4.84424 55.0034 5.37207 55.9489 6.42778C56.8945 7.48344 57.3674 8.89051 57.3674 10.6499V11.1117C57.3674 11.2585 57.3158 11.3833 57.2135 11.4856C57.1106 11.5883 56.9788 11.6396 56.8176 11.6396H49.5167V11.8154C49.5458 12.622 49.7697 13.2999 50.1871 13.8496C50.6051 14.3994 51.1804 14.6743 51.9135 14.6743C52.7786 14.6743 53.4748 14.3373 54.0026 13.6627C54.1346 13.5016 54.2409 13.3989 54.3216 13.3548C54.402 13.3107 54.5229 13.2888 54.6844 13.2888H56.5755C56.7076 13.2888 56.821 13.3293 56.9164 13.4097C57.0115 13.4904 57.0592 13.5895 57.0592 13.7065C57.0592 14.0585 56.8507 14.4729 56.4327 14.9491C56.0149 15.4257 55.4175 15.8398 54.6403 16.1915C53.8634 16.5435 52.9617 16.7193 51.9357 16.7193C50.3083 16.7193 49.0141 16.2467 48.0541 15.3012Z"
        fill="black"
      />
      <path
        d="M61.0685 16.302C60.9659 16.1996 60.9146 16.0748 60.9146 15.9281V7.35176H59.1333C58.9866 7.35176 58.862 7.29683 58.7594 7.18675C58.6568 7.07688 58.6055 6.94876 58.6055 6.802V5.5486C58.6055 5.40217 58.6568 5.27734 58.7594 5.17471C58.862 5.07235 58.9866 5.02077 59.1333 5.02077H60.9146V4.119C60.9146 1.49482 62.3514 0.182861 65.2249 0.182861H69.7108C69.872 0.182861 70.0001 0.230556 70.0958 0.325675C70.191 0.421119 70.2387 0.549241 70.2387 0.71042V1.96415C70.2387 2.1256 70.1871 2.25729 70.0847 2.35964C69.9821 2.46254 69.8573 2.51385 69.7108 2.51385H65.3125C64.7409 2.51385 64.3412 2.6531 64.1143 2.9316C63.8869 3.21005 63.7735 3.64276 63.7735 4.22914V5.02077H69.7108C69.872 5.02077 70.0001 5.06847 70.0958 5.16359C70.191 5.2593 70.2387 5.38742 70.2387 5.5486V15.9281C70.2387 16.0748 70.191 16.1996 70.0958 16.302C70.0001 16.4046 69.872 16.4559 69.7108 16.4559H67.9299C67.7828 16.4559 67.6583 16.4046 67.556 16.302C67.453 16.1996 67.4017 16.0748 67.4017 15.9281V7.35176H63.7735V15.9281C63.7735 16.0748 63.7218 16.1996 63.6195 16.302C63.5166 16.4046 63.3921 16.4559 63.2456 16.4559H61.4424C61.2957 16.4559 61.1709 16.4046 61.0685 16.302Z"
        fill="black"
      />
      <path
        d="M75.1893 15.4182C74.5222 14.7221 74.1888 13.6994 74.1888 12.3505V7.3587H72.4513C72.3046 7.3587 72.1801 7.30349 72.0778 7.19363C71.9748 7.08349 71.9238 6.95537 71.9238 6.80894V5.55521C71.9238 5.40878 71.9748 5.28422 72.0778 5.18132C72.1801 5.07896 72.3046 5.02765 72.4513 5.02765H74.1888V1.37687C74.1888 1.21575 74.2399 1.08762 74.3428 0.992179C74.4451 0.89706 74.5699 0.849365 74.7164 0.849365H76.4979C76.6591 0.849365 76.7872 0.89706 76.8826 0.992179C76.9777 1.08762 77.0255 1.21575 77.0255 1.37687V5.02765H79.7745C79.9354 5.02765 80.0638 5.0754 80.1592 5.17052C80.2544 5.26591 80.3023 5.39408 80.3023 5.55521V6.80894C80.3023 6.97039 80.2508 7.10213 80.1484 7.20476C80.0455 7.30738 79.9207 7.3587 79.7745 7.3587H77.0255V12.1305C77.0255 12.761 77.1353 13.2376 77.3552 13.5598C77.5752 13.8827 77.9416 14.0438 78.4548 14.0438H79.9723C80.1187 14.0438 80.2436 14.0952 80.3462 14.1978C80.4485 14.3004 80.5001 14.4253 80.5001 14.5714V15.935C80.5001 16.0817 80.4524 16.2065 80.357 16.3089C80.2615 16.4115 80.1334 16.4628 79.9723 16.4628H78.191C76.8569 16.4628 75.8564 16.1147 75.1893 15.4182Z"
        fill="black"
      />
    </svg>
  );
};

export default FooterLogo;
