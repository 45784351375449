import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import SignupBaseCard from '@common/components/sign-up-layout/SignupBaseCard';
import Tabs from '@common/components/tabs/Tabs';
import useTracker from '@common/hooks/useTracker';
import { setShowLoader } from '@common/redux/slices/application';
import {
  IdentificationProvider,
  IdentificationProvidersResponse,
} from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';
import { useAppDispatch, useAppSelector } from '@monefit-ee/hooks';
import ErrorBoundaryPage from '@monefit-ee/pages/error-boundary-page/ErrorBoundaryPage';

import IdCardForm from '../providersForms/IdCardForm';
import MobileIdForm from '../providersForms/MobileIdForm';
import SmartIdForm from '../providersForms/SmartIdForm';

const Providers = ({ data }: { data: IdentificationProvidersResponse }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { loading } = useAppSelector((st) => st.application.flowInstance);
  const providersLabelMapper = useMemo(
    () => ({
      [IdentificationProvider.SMART_ID]: formatMessage({
        defaultMessage: 'Smart-ID',
        description: 'Identification: "Smart-ID"',
      }),
      [IdentificationProvider.MOBILE_ID]: formatMessage({
        defaultMessage: 'Mobile-ID',
        description: 'Identification: "Mobile-ID"',
      }),
      [IdentificationProvider.ID_CARD]: formatMessage({
        defaultMessage: 'ID-Card',
        description: 'Identification: "ID-Card"',
      }),
    }),
    [formatMessage]
  );

  const providersComponentMapper = useMemo(
    () => ({
      [IdentificationProvider.SMART_ID]: <SmartIdForm />,
      [IdentificationProvider.MOBILE_ID]: <MobileIdForm />,
      [IdentificationProvider.ID_CARD]: <IdCardForm />,
    }),
    []
  );

  const providerEventMapper = useMemo(
    () => ({
      [IdentificationProvider.SMART_ID]: TrackEvent.SIGNUP_PAGE_SMART_ID_SELECT,
      [IdentificationProvider.MOBILE_ID]: TrackEvent.SIGNUP_PAGE_MOBILE_ID_SELECT,
      [IdentificationProvider.ID_CARD]: TrackEvent.SIGNUP_PAGE_ID_CARD_SELECT,
    }),
    []
  );

  const { trackEvent } = useTracker({ errorTrackEventName: TrackEvent.SINGUP_PAGE_ERROR });

  const tabs = useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }
    return data.map((provider) => {
      return {
        title:
          providersLabelMapper[provider] ??
          formatMessage({ defaultMessage: 'N/A', description: 'Identification: "N/A"' }),
        content: providersComponentMapper[provider] ?? null,
        onClick: () => trackEvent(providerEventMapper[provider]),
      };
    });
  }, [
    data,
    formatMessage,
    providerEventMapper,
    providersComponentMapper,
    providersLabelMapper,
    trackEvent,
  ]);

  useEffect(() => {
    dispatch(setShowLoader(true));
  }, []);

  if (!tabs.length && !loading) {
    return <ErrorBoundaryPage hasSignupLayout={false} />;
  }

  return (
    <SignupBaseCard
      headerText={formatMessage({
        defaultMessage: 'Sign up',
        description: 'Identification: "Sign up"',
      })}
      subHeaderText={formatMessage({
        defaultMessage: 'Create your CreditLine account with your ID.',
        description: 'Identification: "Create your CreditLine account with your ID."',
      })}
    >
      <div className="w-full items-center justify-center">
        <Tabs tabs={tabs ?? []} />
      </div>
    </SignupBaseCard>
  );
};

export default Providers;
