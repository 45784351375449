export enum ReactAppId {
  CREDITSTAR = 'creditstar',
  MONEFIT = 'monefit',
}

/**
 * Hook to get the brand of currently built application
 */
export const useBrand = () => {
  const { REACT_APP_ID } = process.env;

  const brand = Object.values(ReactAppId).includes(REACT_APP_ID as ReactAppId)
    ? (REACT_APP_ID as ReactAppId)
    : null;

  return { brand };
};
