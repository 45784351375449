import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { linkBasedRegister } from '@common/redux/thunks/authentication';
import {
  LinkBasedRegisterResponse,
  Region,
  RegisterChannel,
} from '@common/services/authentication';
import SignupForm from '@monefit-es/components/forms/SignupForm';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import CheckEmailCard from '@monefit-es/components/loaders/CheckEmailCard';
import { useAppDispatch } from '@monefit-es/hooks';
import { useAppSelector } from '@monefit-es/hooks';

const SignupPage = () => {
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('referrer') ?? null;
  const { data } = useAppSelector((st) => st.authentication);
  const dispatch = useAppDispatch();

  const handleResend = useCallback(async () => {
    const typedData = (data as LinkBasedRegisterResponse) ?? {};
    const { displayName, identifier } = typedData;
    await dispatch(
      linkBasedRegister({
        displayName,
        identifier,
        referrer,
        channel: RegisterChannel.EMAIL,
        region: Region.ES,
      })
    ).unwrap();
  }, [data, dispatch, referrer]);
  return (
    <>
      {(data as LinkBasedRegisterResponse)?.outcome === 'success' ? (
        <MainLayout showLoader={false}>
          <CheckEmailCard
            email={(data as LinkBasedRegisterResponse)?.identifier ?? ''}
            handleResend={handleResend}
          />
        </MainLayout>
      ) : (
        <MainLayout>
          <SignupForm referrer={referrer} />
        </MainLayout>
      )}
    </>
  );
};

export default SignupPage;
