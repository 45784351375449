import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import CopyIcon2 from '@common/components/icons/CopyIcon2';
import { LocalStorageKeys, MONEFIT_PUBLIC_SITE_URL } from '@common/constants';
import { useLocale } from '@common/hooks';
import useTailwindBreakpoints, { TWBreakPoint } from '@common/hooks/useTailwindBreakpoints';
import { getUserData, getUserDisplayName } from '@common/utils';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import WelcomeTextLoader from '@monefit-es/components/loaders/WelcomeTextLoader';
import { useAppSelector } from '@monefit-es/hooks';
import waiting_list_1_img from '@src/assets/img/monefit-es/waiting_list_1.webp';

import WaitingListFAQ from './WaitingListFAQ';
import WaitingListSmartSaver from './WaitingListSmartSaver';
import ComingSoon from './coming-soon/ComingSoon';

const MAIN_CARD_LIST_ITEMS = [
  <FormattedMessage defaultMessage="Up to 10,000 €" description="Monefit-ES:  Up to 10,000 €" />,
  <FormattedMessage
    defaultMessage="Zero interest when you pay on time"
    description="Monefit-ES: Zero interest when you pay on time"
  />,
  <FormattedMessage
    defaultMessage="Transparency: no hidden costs"
    description="Monefit-ES: Transparency: no hidden costs"
  />,
  <FormattedMessage defaultMessage="Simple to use" description="Moenfit-ES: Simple to use" />,
  <FormattedMessage
    defaultMessage="Instant withdrawal"
    description="Moenfit-ES: Instant withdrawal"
  />,
];

const UserDisplayName = () => {
  const userName = getUserDisplayName();
  return (
    <>
      <div className="flex flex-col items-center gap-1">
        <div className="font-secondary w-full break-words text-center text-3xl font-semibold text-black">
          <FormattedMessage
            defaultMessage="Congratulations {userName}"
            values={{ userName }}
            description="Monefit-ES : Congratulations {userName}"
          />
        </div>
        <span className="text-center text-xl font-medium text-black">
          <FormattedMessage
            defaultMessage="You are accepted to the waiting list."
            description="Monefit-ES :   You are accepted to the waiting list."
          />
        </span>
      </div>
    </>
  );
};

const WaitingListPage = () => {
  const breakpoint = useTailwindBreakpoints();
  const { loading } = useAppSelector((st) => st.authentication);
  const { formatMessage } = useIntl();
  const welcomeAnimationShownThisSession = Boolean(
    sessionStorage.getItem(LocalStorageKeys.WELCOME_SHOWN)
  );
  const [welcomeAnimationComplete, setWelcomeAnimationComplete] = useState(
    welcomeAnimationShownThisSession
  );

  const handleAnimationEnd = useCallback(() => {
    setWelcomeAnimationComplete(true);
    sessionStorage.setItem(LocalStorageKeys.WELCOME_SHOWN, JSON.stringify(true));
  }, []);
  const { localeWithCountry } = useLocale();

  const isMobile = useMemo(() => breakpoint === TWBreakPoint.SM, [breakpoint]);
  const referralLink = useMemo(() => {
    const userData = getUserData();

    return `${MONEFIT_PUBLIC_SITE_URL}/${localeWithCountry}/credit/?signup&referrer=${userData?.user?.referralCode ?? ''}`;
  }, [localeWithCountry]);

  const copyToClipboard = useCallback(
    (value: string) => {
      navigator.clipboard.writeText(value);
      toast(
        formatMessage({ defaultMessage: 'Link copied', description: 'Monefit-ES: Link copied' }),
        { type: 'success', toastId: '1' }
      );
    },
    [formatMessage]
  );

  const handleCopyLink = useCallback(() => {
    if (isMobile) {
      try {
        navigator.share({
          url: referralLink,
          title: formatMessage({
            defaultMessage: 'Monefit Credit. Chill With 0% Interest',
            description: 'Monefit-ES: Monefit Credit. Chill With 0% Interest',
          }),
        });
      } catch (_) {
        copyToClipboard(referralLink);
      }
    } else {
      copyToClipboard(referralLink);
    }
  }, [isMobile, referralLink, copyToClipboard, formatMessage]);

  return (
    <>
      {welcomeAnimationComplete ? (
        <div className="animate-fadeInLong">
          <MainLayout hideHeaderAndFooter={loading} userDisplayNameNode={<UserDisplayName />}>
            <div className="flex w-full max-w-[1088px] flex-col gap-3">
              <div
                className="relative inline-flex w-full flex-col items-start justify-start gap-3 rounded-3xl bg-black bg-gradient-to-r p-12"
                style={{
                  ...(!isMobile && { backgroundImage: `url(${waiting_list_1_img})` }),
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <div className="mb-3 sm:absolute sm:right-12 sm:mb-0">
                  <ComingSoon />
                </div>
                <div className="flex flex-col items-start justify-start gap-1">
                  <div className="flex flex-col items-start justify-start gap-2.5">
                    <div
                      style={{ letterSpacing: '0em' }}
                      className="font-secondary text-center text-3xl font-semibold leading-10 text-[#9AE7FF] sm:text-5xl"
                    >
                      <FormattedMessage
                        defaultMessage="Monefit Credit"
                        description="Monefit-ES: Monefit Credit"
                      />
                    </div>
                    <div className="text-lg font-medium leading-snug text-white">
                      <FormattedMessage
                        defaultMessage="Interest-free creditline is coming soon"
                        description="Monefit-ES Interest-free creditline is coming soon"
                      />
                    </div>
                  </div>
                </div>
                <div className="inline-flex items-start justify-start gap-5 self-stretch">
                  <ul className="list-inside list-disc font-normal text-white sm:text-lg">
                    {MAIN_CARD_LIST_ITEMS.map((item, i) => (
                      <li className="mb-2" key={i}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="flex w-full flex-col gap-3 sm:w-[360px]">
                  <div className="inline-flex w-full items-center justify-between rounded-2xl border bg-white p-3.5">
                    <div className="inline-flex flex-col items-start justify-center gap-0 overflow-hidden pr-2">
                      <div className="w-full text-xs font-medium tracking-tight text-gray-400">
                        <FormattedMessage
                          defaultMessage="Invite friends and gain early access"
                          description="Monefit-ES: Invite friends and gain early access"
                        />
                      </div>
                      <div
                        className="w-full cursor-pointer truncate text-sm font-medium tracking-tight text-black sm:w-[310px]"
                        onClick={handleCopyLink}
                        role="presentation"
                      >
                        {referralLink}
                      </div>
                    </div>
                    <button onClick={handleCopyLink}>
                      <CopyIcon2 />
                    </button>
                  </div>
                </div>
              </div>

              <WaitingListFAQ />
              <WaitingListSmartSaver />
            </div>
          </MainLayout>
        </div>
      ) : loading ? null : (
        <WelcomeTextLoader onComplete={handleAnimationEnd} />
      )}
    </>
  );
};

export default WaitingListPage;
