import React, { ReactNode, useEffect, useRef, useState } from 'react';

interface AccordionProps {
  title: ReactNode;
  children: ReactNode;
  defaultOpen?: boolean;
}

const PlusIcon = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <div>
      <svg
        className={`transition-transform ${isOpen ? 'rotate-45' : ''}`}
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M16.8075 10C17.3598 10 17.8075 9.55229 17.8075 9.00001C17.8075 8.44772 17.3598 8.00001 16.8075 8.00001L10.0292 8.00001L10.0292 1.2219C10.0292 0.669612 9.58152 0.221897 9.02923 0.221897C8.47695 0.221897 8.02923 0.669612 8.02923 1.2219L8.02923 8.00001L1.25112 8.00001C0.698837 8.00001 0.251124 8.44772 0.251122 9.00001C0.251122 9.55229 0.698836 10 1.25112 10L8.02923 10L8.02923 16.7782C8.02923 17.3305 8.47695 17.7782 9.02923 17.7782C9.58152 17.7782 10.0292 17.3305 10.0292 16.7782L10.0292 10L16.8075 10Z"
          fill="#15171B"
        />
      </svg>
    </div>
  );
};

const Accordion = ({ title, children, defaultOpen }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen ?? false);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [isOpen]);

  return (
    <div className="rounded-3xl bg-white p-6 hover:bg-black hover:bg-opacity-5">
      <button
        className="flex w-full items-baseline justify-between p-4 text-left focus:outline-none"
        onClick={toggleAccordion}
      >
        <span className="font-secondary text-2xl font-semibold leading-7 text-black ">{title}</span>

        <PlusIcon isOpen={isOpen} />
      </button>
      <div
        ref={contentRef}
        style={{ maxHeight: `${height}` }}
        className="overflow-hidden duration-300 ease-in-out"
      >
        <div className="px-4 text-lg font-normal leading-normal text-black">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
