// src/components/AccordionGroup.tsx
import React, { ReactNode } from 'react';

import Accordion from './Accordion';

interface AccordionGroupProps {
  items: { title: ReactNode; content: ReactNode; defaultOpen?: boolean }[];
}

const AccordionGroup = ({ items }: AccordionGroupProps) => {
  return (
    <div className="flex w-full flex-col gap-2">
      {items.map((item, index) => (
        <Accordion key={index} title={item.title} defaultOpen={item.defaultOpen}>
          {item.content}
        </Accordion>
      ))}
    </div>
  );
};

export default AccordionGroup;
