import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Form from '@common/components/form/Form';
import TextInput from '@common/components/form/inputs/textInput/TextInput';
import { useApplicationFlowInstance } from '@common/hooks';
import { setCurrentSubstepId } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import {
  FlowActions,
  IdentificationProvider,
  IdentificationReason,
  SubStep,
} from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';
import { useAppDispatch } from '@monefit-ee/hooks';
import * as yup from 'yup';

interface FormValues {
  identificator: string;
}

const FIELDS_TO_LOG_ON_CHANGE = [
  {
    fieldName: 'identificator',
    event: TrackEvent.SIGNUP_PAGE_DIGIT_ENTETERED,
  },
];

const SmartIdForm = () => {
  const [instanceId] = useApplicationFlowInstance();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const schema: yup.ObjectSchema<FormValues> = useMemo(
    () =>
      yup.object({
        identificator: yup.string().required(
          formatMessage({
            defaultMessage: 'Personal code is required',
            description: 'Identification: "Personal code is required"',
          })
        ),
      }),
    [formatMessage]
  );

  return (
    <Form
      submitTrackEventName={TrackEvent.SIGNUP_PAGE_SUBMIT}
      fieldsToTrackOnChange={FIELDS_TO_LOG_ON_CHANGE}
      onSubmit={(values: FormValues) => {
        dispatch(
          performApplicationFlowAction({
            instanceId,
            action: FlowActions.SUBMIT,
            data: {
              provider: IdentificationProvider.SMART_ID,
              reason: IdentificationReason.REGISTER,
              identificator: values.identificator,
            },
          })
        )
          .unwrap()
          .then(() => dispatch(setCurrentSubstepId(SubStep.SMART_ID_PENDING)));
      }}
      schema={schema}
      gapClass="gap-6"
    >
      <TextInput
        type="tel"
        autoComplete="off"
        name="identificator"
        label={formatMessage({
          defaultMessage: 'Personal code',
          description: 'Identification: "Personal code" (input label)',
        })}
      />
    </Form>
  );
};

export default SmartIdForm;
