import { LocalStorageKeys } from '@common/constants';
import {
  fetchApplicationFlowInstance,
  performApplicationFlowAction,
} from '@common/redux/thunks/application';
import { FlowDefinition, GetPostFlowInstanceResponse, SubStep } from '@common/services/application';
import { createSlice } from '@reduxjs/toolkit';

interface ApplicationState {
  flowInstance: {
    loading: boolean;
    error: boolean;
    data: GetPostFlowInstanceResponse | null;
    flowDefinition: FlowDefinition | null;
    meta: {
      currentSubStepId: SubStep | null;
      showLoader: boolean;
      enableGetRequest: boolean;
    };
  };
}

const initialState: ApplicationState = {
  flowInstance: {
    loading: false,
    error: false,
    data: null,
    flowDefinition: null,
    meta: {
      currentSubStepId: null,
      showLoader: true,
      enableGetRequest: true,
    },
  },
};

/**
 * Application state handling
 */
const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setCurrentSubstepId(state, action) {
      return {
        ...state,
        flowInstance: {
          ...state.flowInstance,
          meta: {
            ...state.flowInstance.meta,
            currentSubStepId: action.payload ?? null,
          },
        },
      };
    },
    setShowLoader(state, action) {
      return {
        ...state,
        flowInstance: {
          ...state.flowInstance,
          meta: {
            ...state.flowInstance.meta,
            showLoader: action.payload,
          },
        },
      };
    },
    reset(state) {
      sessionStorage.removeItem(LocalStorageKeys.APPLICATION_FLOW_INSTANCE_ID);
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchApplicationFlowInstance.pending, (state) => ({
      ...state,
      flowInstance: {
        ...state.flowInstance,
        loading: true,
        error: false,
      },
    }));

    builder.addCase(fetchApplicationFlowInstance.fulfilled, (state, action) => ({
      ...state,
      flowInstance: {
        ...state.flowInstance,
        data: action.payload,
        loading: false,
        error: false,
      },
    }));

    builder.addCase(fetchApplicationFlowInstance.rejected, (state) => ({
      ...state,
      flowInstance: {
        ...state.flowInstance,
        loading: false,
        error: true,
      },
    }));

    builder.addCase(performApplicationFlowAction.pending, (state, action) => ({
      ...state,
      flowInstance: {
        ...state.flowInstance,
        loading: true,
        error: false,
      },
    }));

    builder.addCase(performApplicationFlowAction.fulfilled, (state, action) => ({
      ...state,
      flowInstance: {
        ...state.flowInstance,
        data: action.payload ?? null,
        loading: false,
        error: false,
        meta: {
          ...state.flowInstance.meta,
          enableGetRequest: false,
        },
      },
    }));

    builder.addCase(performApplicationFlowAction.rejected, (state) => ({
      ...state,
      flowInstance: {
        ...state.flowInstance,
        loading: false,
        error: true,
        meta: {
          ...state.flowInstance.meta,
          enableGetRequest: true,
        },
      },
    }));
  },
});

export const { setCurrentSubstepId, reset, setShowLoader } = applicationSlice.actions;

export default applicationSlice.reducer;
