export enum LoanPurpose {
  Bills = 'Bills',
  Travelling = 'Vacation',
  Rent_or_loan = 'Rent_or_mortage',
  Car = 'Car',
  Entertainment = 'Entertainment',
  Groceries = 'Groceries',
  Repair = 'Renovation',
  Home_appliance = 'Electronics',
  Furniture = 'Furniture',
  Education = 'School',
  Other = 'Other',
}

export enum EducationType {
  Basic_education = 'Basic',
  Primary_education = 'Secondary',
  Secondary_education = 'Upper_secondary',
  Vocational_education = 'Vocational',
  Applied_higher_education = 'Professional_higher',
  Higher_education = 'Higher',
}

export enum EmploymentStatus {
  Salary = 'Salary',
  Self_employed = 'Self_employment',
  Scholarship = 'Student',
  Pension = 'Pensioner',
  Grants_Benefits = 'Social_benefits',
  No_income = 'No_income',
}

export enum FamilyStatus {
  Married = 'Married',
  Single = 'Single',
  Divorced = 'Divorced',
  Domestic_partnership = 'Domestic_partnership',
}

export enum LoanType {
  Consumer_credit = 'Consumer_credit',
  House_loan = 'Housing_loan',
  Leasing = 'Leasing',
  Credit_card = 'Credit_card',
  Other = 'Other',
}

export enum FieldOfActivity {
  Agriculture_forestry_fishing = 'Agriculture',
  Construction = 'Construction',
  Wholesale_and_retail_trade = 'Wholesale_and_retail_trade',
  Transport_and_logistics = 'Transportation',
  Tourism_accommodation_and_catering = 'Tourism_accommodation_and_food_service_activities',
  Information_and_communication = 'Information_and_communication',
  Finance_and_insurance = 'Financial_and_insurance_activities',
  Public_administration_and_national_defense = 'Public_administration_and_defence',
  Education = 'Education',
  Health_care_and_social_welfare = 'Human_health_and_social_work_activities',
  Culture_arts_and_entertainment = 'Entertainment_and_recreation',
  Other_service_activities = 'Other_service_activities',
}

export enum PropertyType {
  REAL_ESTATE = 'Real_estate',
  VEHICLE = 'Personal_vehicle',
  STOCK = 'Funds',
}

export interface ActiveCredit {
  activeCreditAmount: number | null;
  activeCreditType: string | null; // TODO: should be replaced with enum later (?)
}

export interface FinancesFormValues {
  loanPurpose: string; // TODO: should be replaced with enum later (?)
  education: string; // TODO: should be replaced with enum later (?)
  maritalStatus: string; // TODO: should be replaced with enum later (?)
  monthlyExpenses: number;
  monthlyNetIncome: number;
  employmentDuration?: number;
  employmentType: string; // TODO: should be replaced with enum later (?)
  financialDependents: number;
  hasCreditHistory: boolean;
  hasActiveCredit: boolean;
  isPep: boolean;
  propertyOwner: boolean;
  propertyTypes?: string[];
  employerName?: string;
  jobTitle?: string;
  fieldOfActivity?: string;
  activeCredits?: ActiveCredit[];
}
